import pureAxios from 'axios';
// import axios from 'axios';

const axios = pureAxios.create({
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
  },
});

axios.interceptors.request.use(
  function (successfulReq) {
    //   ...modify code;
    // if (successfulReq?.url?.includes('/api'))
    //   successfulReq.headers['Authorization'] = `Bearer ${localStorage.getItem('id_token')}`;
    if (successfulReq?.url?.includes('/api')) {
      const token = localStorage.getItem('id_token');
      if (token) {
        successfulReq.headers['Authorization'] = `Bearer ${token}`;
      } else {
        console.warn('Token is not available; API call may fail.');
        // Optional: Decide if you want to prevent the request or proceed without a token
        // return Promise.reject(new Error('Token not initialized'));
      }
    }
    return successfulReq;
  },
  function (error) {
    // ...

    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (successRes) {
    // console.log(successRes);
    return successRes;
  },
  function axiosLogoutInterceptor(err) {
    const originalRequest = err.config;

    console.log(err.response?.config?.url);

    if (
      err.response?.config?.url.includes('/api') &&
      err.response?.status === 401 &&
      !originalRequest._retry
    ) {
      // authFlow();
      originalRequest._retry = true;
      return axios.request(originalRequest);
    } else if (originalRequest?._retry) {
      originalRequest._retry = false;
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(err);
  },
);

/*axios.interceptors.response.use(
  function (response) {
    // if (response?.data?.message) showAlert(response.data);
    return response;
  },
  function (error) {
    // if (error?.response?.data?.message) showAlert(error.response.data, 'error');
    // Do something with response error
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);*/

// axios.interceptors.request.use(
//   function (successfulReq) {
//     //   ...modify code;

//     if (successfulReq.url.includes('/api'))
//       successfulReq.headers['Authorization'] = `Bearer ${localStorage.getItem('id_token')}`;
//     return successfulReq;
//   },
//   function (error) {
//     // ...

//     return Promise.reject(error);
//   },
// );

// axios.interceptors.response.use(
//   function (successRes) {
//     // console.log(successRes);
//     return successRes;
//   },
//   function axiosLogoutInterceptor(err) {
//     const originalRequest = err.config;

//     // console.log(err.response?.config?.url);

//     if (
//       err.response?.config?.url.includes('/api') &&
//       err.response?.status === 401 &&
//       !originalRequest._retry
//     ) {
//       // authFlow();
//       originalRequest._retry = true;
//       return axios.request(originalRequest);
//     } else if (originalRequest?._retry) {
//       //   localStorage.clear();
//       window.location.href = '/login';
//     }
//     return Promise.reject(err);
//   },
// );

export default axios;
