import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Row, Col, Container, Modal, Button, Badge, Spinner } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { useLocation } from 'react-router-dom';
import { fileRegex, getFileNameRegex } from '../../../utils/Utils';
import { UserContext } from '../../../context/UserContext';
import ticketsService from '../../../utils/ticketsService';
import { useFormik } from 'formik';

import { MsalAuthenticationTemplate, useMsal, useAccount } from '@azure/msal-react';
import { FALSE } from 'sass';

const ApprovalReview = (props) => {
  let { ticketNum } = useParams();
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();

  function getLang(id) {
    // console.log(props.data.translations);
    // let obj = props.data.translations.find((o) => o.textID === id);
    // // console.log(obj);
    // if (!obj) {
    //   return id;
    // } else {
    //   return obj.localizedText;
    // }
    return id;
  }
  function handleCopyTicketClick(ticketNum, reqType, creationType, customerRequest) {
    console.log('HANDLE COPY CLICK', ticketNum, reqType, creationType, customerRequest);
    props.openRequest(ticketNum, reqType, creationType, customerRequest);
    // print('Customer Request', customerRequest);
    // console.log(e.target);
    // if (e.target.getAttribute('data-ticket')) props.openRequest(e.target.getAttribute('data-ticket'), e.target.getAttribute('data-reqtype'));
  }
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModalVendor, setShowModalVendor] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showApprovalButton, setShowApprovalButton] = useState(false);
  const [vendorType, setVendorType] = useState('New');
  const [showVendorModalSpinner, setShowVendorModalSpinner] = useState(false);

  const vendorTypes = [
    { value: 'New', label: 'New' },
    { value: 'Existing', label: 'Existing' },
  ];

  function handleUpdateVendorModal() {
    console.log('Pop Up For vendor creation');
  }

  function handleUpdateVendorInfo(vendorType, vendorNum, vendorIssues) {
    console.log('hehe api here');
    console.log('Inside API', vendorType, vendorNum);
    setShowVendorModalSpinner(true);
    ticketsService
      .updateVendorRequest(
        accounts && accounts.length > 0 ? accounts[0].username : '',
        props?.data?.title,
        vendorType,
        vendorNum,
        vendorIssues,
      )
      .then((res) => {
        console.log(res?.result);
        setShowVendorModalSpinner(false);
        handleClose();
        navigate('/');
        // setShowApprovalButton(res?.result);
      });
  }

  function handleClose() {
    setShowModalVendor(false);
  }

  const formik = useFormik({
    initialValues: {
      vendorType: null,
      vendorNum: null,
      vendorIssue: null,
    },
    validate: (values) => {
      const errors = {};
      if (!values.vendorType) {
        errors.vendorType = 'Please enter a vendor type';
      }
      if (values.vendorType !== 'Problem' && !values.vendorNum) {
        errors.vendorNum = 'Please enter a vendor number';
      }

      if (values.vendorType == 'Problem' && !values.vendorIssue) {
        errors.vendorIssue = 'Please enter your issue type';
      }

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      // setTimeout(() => {
      //   console.log(values);
      //   setSubmitting(false);
      // }, 400);
      console.log(values);
      handleUpdateVendorInfo(values.vendorType, values.vendorNum, values.vendorIssue);
    },
  });

  console.log(props.data);
  useEffect(() => {
    ticketsService
      .getApprovalButton(
        accounts && accounts.length > 0 ? accounts[0].username : '',
        props?.data?.title,
        props?.data?.requestType,
      )
      .then((res) => {
        setShowApprovalButton(res?.result);
      });
  }, [props?.data]);

  console.log(props.data);
  // console.log("Customer REquest tYPE" , props?.data?.mainPage?.reqType)

  return (
    <>
      <form className="card">
        <div className="card-header">
          <Row className="justify-content-between align-items-center col-12">
            <Col lg={12}>
              <Row>
                <Col lg={10}>
                  <h3 className="card-title text-left">
                    <strong>{`Review Request ${props?.data?.title}`}</strong>
                    {props?.data?.status ? ` - ${props?.data?.status}` : null}
                    {/* <Badge pill variant="warning" className="ml-2">
                {'Awaiting Approval'}
              </Badge> */}
                  </h3>
                </Col>
                <Col lg={2}>
                  {(props.data.status == 'Rejected' || props.data.status == 'Duplicate') && (
                    <Button
                      size="sm"
                      onClick={() => {
                        handleCopyTicketClick(
                          props?.data?.title,
                          props.data.requestType,
                          'Customer',
                          props.data.requestType == 'Modification' ||
                            props.data.RequestType == 'Block/Unblock'
                            ? props.data.mainPage.reqType
                            : props.data.mainpage.customerReqType,
                        );
                      }}
                      style={{ height: '2rem', width: '10rem' }}
                    >
                      Copy Ticket
                    </Button>
                  )}
                </Col>
                <Col lg={2}>
                  {!props.data.status && (
                    <Button
                      size="sm"
                      onClick={() => {
                        handleCopyTicketClick(
                          props?.data?.title,
                          props.data.requestType,
                          'Customer',
                          props.data.requestType == 'Modification' ||
                            props.data.RequestType == 'Block/Unblock'
                            ? props.data.mainPage.reqType
                            : props.data.mainpage.customerReqType,
                        );
                      }}
                      style={{ height: '2rem', width: '10rem' }}
                    >
                      Continue Editing
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
            {pathname.includes('/review') &&
              (props.data.status === 'Completed' && !props.data.vendorNum ? (
                <Col>
                  <Row>
                    <Col lg={2}>
                      <Button
                        size="sm"
                        onClick={() => {
                          handleUpdateVendorModal();
                          setShowModalVendor(true);
                        }}
                        style={{ height: '2rem', width: '10rem' }}
                      >
                        Update Vendor
                      </Button>
                    </Col>
                  </Row>
                  {props?.data?.mainpage?.vendorIssue && (
                    <Row>
                      <Col lg={6}>
                        <label className="form-label text-left">Issue Notes</label>
                        <textarea
                          value={props?.data?.mainpage?.vendorIssue}
                          disabled
                          style={{ width: '100%', height: '100px' }}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              ) : (
                showApprovalButton && (
                  <Col>
                    <Row>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={() => {
                          props.review.onReject(props.data.title);
                        }}
                      >
                        <FeatherIcon icon="x" />
                        &emsp; Reject
                      </Button>
                      <Button
                        className="ml-1"
                        size="sm"
                        variant={
                          user.role !== 'Cr Manager' && user.role !== 'Second Cr Manager'
                            ? 'success'
                            : 'warning'
                        }
                        onClick={() => {
                          props.review.onApprove(props.data.title);
                        }}
                      >
                        <FeatherIcon
                          icon={
                            user.role !== 'Cr Manager' && user.role !== 'Second Cr Manager'
                              ? 'check'
                              : 'file-plus'
                          }
                        />
                        &emsp;{' '}
                        {user.role === 'Cr Manager' || user.role === 'Second Cr Manager'
                          ? 'Update'
                          : 'Approve'}
                      </Button>
                    </Row>
                  </Col>
                )
              ))}
          </Row>
        </div>

        <div className="card-body text-left">
          {props.data.requestType == 'Creation' && (
            <>
              {(props.data.mainpage.customerReqType == '2nd Sold To' ||
                props.data.mainpage.customerReqType == 'Full Creation' ||
                props.data.mainpage.customerReqType == 'Listing Fees' ||
                props.data.mainpage.customerReqType == 'New Owner' ||
                props.data.mainpage.customerReqType == 'Prospect' ||
                props.data.mainpage.customerReqType == 'Ship To') && (
                <>
                  <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header>
                      <Modal.Title>Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img src={props.data.payer.supportDocsBank}></img>
                    </Modal.Body>
                  </Modal>

                  <Modal show={showModal1} onHide={() => setShowModal1(false)}>
                    <Modal.Header>
                      <Modal.Title>Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img src={props.data.payer.supportDocsBank1}></img>
                    </Modal.Body>
                  </Modal>

                  <Modal show={showModalVendor} onHide={() => setShowModalVendor(false)}>
                    <form onSubmit={formik.handleSubmit}>
                      <Modal.Header>
                        <Modal.Title>
                          Update Vendor Information for {props?.data?.title}
                        </Modal.Title>
                      </Modal.Header>
                      {showVendorModalSpinner && (
                        <Modal.Body>
                          <Row>
                            <Col xs={12} className="text-center">
                              <Spinner animation="grow" variant="warning" />
                            </Col>
                          </Row>
                        </Modal.Body>
                      )}
                      <Modal.Body>
                        <Col>
                          <Row>
                            <div className="form-group">
                              <label className="form-label text-left">Vendor Type *</label>
                              <select
                                className={`form-control ${
                                  formik.errors.vendorType ? 'is-invalid' : ''
                                }`}
                                name="vendorType"
                                value={formik.values.vendorType}
                                onChange={(e) => {
                                  formik.setFieldValue('vendorType', e?.target?.value);
                                }}
                                // getOptionLabel={({ vendorTypes }) => vendorTypes.label}
                                // getOptionValue={({ vendorTypes }) => vendorTypes.value}
                                required
                              >
                                <option value="">Select</option>
                                <option value="New">{`New`}</option>
                                <option value="Existing">{`Existing`}</option>
                                <option value="Problem">{`Problem`}</option>
                              </select>
                            </div>
                          </Row>
                          {formik?.values?.vendorType !== 'Problem' && (
                            <Row>
                              <div className="form-group">
                                <label className="form-label text-left">Vendor Number *</label>
                                <input
                                  type="number"
                                  className={`form-control ${
                                    formik.errors?.vendorNum ? 'is-invalid' : ''
                                  }`}
                                  placeholder={`Vendor Number`}
                                  name="vendorNum"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.vendorNum}
                                />
                                {formik.errors?.vendorNum && (
                                  <div className="invalid-feedback text-left">
                                    {formik.errors?.vendorNum}
                                  </div>
                                )}
                              </div>
                            </Row>
                          )}
                          {formik?.values?.vendorType == 'Problem' && (
                            <Row>
                              <div className="form-group">
                                <label className="form-label text-left">Vendor Comments*</label>
                                <textarea
                                  type="text"
                                  className={`form-control ${
                                    formik.errors?.vendorIssue ? 'is-invalid' : ''
                                  }`}
                                  placeholder={`Issue`}
                                  name="vendorIssue"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.vendorIssue}
                                />
                                {formik.errors?.vendorIssue && (
                                  <div className="invalid-feedback text-left">
                                    {formik.errors?.vendorIssue}
                                  </div>
                                )}
                              </div>
                            </Row>
                          )}
                        </Col>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          <FeatherIcon icon="x" />
                          {` Cancel`}
                        </Button>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                        >
                          <FeatherIcon icon="check" />
                          {` Update`}
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal>

                  <Modal show={showModal2} onHide={() => setShowModal2(false)}>
                    <Modal.Header>
                      <Modal.Title>Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img src={props.data.payer.supportDocsBank2}></img>
                    </Modal.Body>
                  </Modal>

                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Customer Request')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.customerReqType}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Ship-to Type')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.shipToType}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('M1')}</label>
                            <div className="form-control-textfield">{props.data.mainpage.m1}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('M2')}</label>
                            <div className="form-control-textfield">{props.data.mainpage.m2}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor_EmailID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.Requestor_EmailID}
                            </div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={2}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Ship To')}</h2>
                        </Col>
                        {!['Full Creation', 'Listing Fees', 'Ship To'].includes(
                          props.data.mainpage.customerReqType,
                        ) && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Existing Ship-to ID')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.shipto.existingShipToID}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">{props.data.shipto.name}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')} 2</label>
                            <div className="form-control-textfield">{props.data.shipto.name2}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.shipto.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">{props.data.shipto.street}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.shipto.houseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">{props.data.shipto.city}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')}</label>
                            <div className="form-control-textfield">
                              {props.data.shipto.regionDesc}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Language')}</label>
                            <div className="form-control-textfield">
                              {props.data.shipto.language}
                            </div>
                          </div>
                        </Col>
                        {/* {props.data.shipto.telephoneOption === 'General/Accounting' && ( */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Telephone')}</label>
                            <div className="form-control-textfield">
                              {props.data.shipto.generalTelephone}
                            </div>
                          </div>
                        </Col>
                        {/* // )} */}
                        {/* // {props.data.shipto.telephoneOption === 'Logistics' && ( */}
                        {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Logistics Telephone')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.shipto.logTelephone}
                            </div>
                          </div>
                        </Col> */}
                        {/* // )} */}
                        {/* // {props.data.shipto.telephoneOption === 'Invoicing' && ( */}
                        {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Invoicing Telephone')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.shipto.invTelephone}
                            </div>
                          </div>
                        </Col> */}
                        {/* // )} */}
                        {/* // {props.data.shipto.telephoneOption === 'Ordering' && ( */}
                        {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Ordering Telephone')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.shipto.orderTelephone}
                            </div>
                          </div>
                        </Col> */}
                        {/* // )} */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Mobile')}</label>
                            <div className="form-control-textfield">{props.data.shipto.mobile}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')}
                            </label>
                            <div className="form-control-textfield">{props.data.shipto.email}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Contact Person')}
                            </label>
                            <div className="form-control-textfield" inputMode="text">
                              {props.data.shipto.contactPerson}
                            </div>
                          </div>
                        </Col>
                        {props.data.shipto.profitCenter && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Profit Center')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.shipto.profitCenter}
                              </div>
                            </div>
                          </Col>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('EDI')}</label>
                            <div className="form-control-textfield">
                              {props.data.shipto.ediOption}
                            </div>
                          </div>
                        </Col>
                        {props.data.shipto.ediOption === 'Yes' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('GLN/ILN CODE')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.shipto.glnIlnCode}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('EDI Email Address')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.shipto.ediEmail}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Sales Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Segment')}</label>
                            <div className="form-control-textfield">
                              {props.data.shipto.segment}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Sub Segment')}</label>
                            <div className="form-control-textfield">
                              {props.data.shipto.subSegment}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Excise duty type')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.shipto.exciseDutyType}
                            </div>
                          </div>
                        </Col>
                        {((props.data.mainpage.country === 'United Kingdom' &&
                          props.data.mainpage.shipToType in
                            ['On-Trade – Indirect', 'Miscellaneous']) ||
                          (props.data.mainpage.country in ['Netherlands', 'France'] &&
                            props.data.mainpage.shipToType.endsWith('Indirect'))) && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('POC Supplier')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.shipto.pocSupplierShipTo}
                              </div>
                            </div>
                          </Col>
                        )}
                        {(props.data.mainpage.country === 'United Kingdom' ||
                          props.data.mainpage.country === 'Netherlands') && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Shipping Condition')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.shipto.shippingCondition}
                              </div>
                            </div>
                          </Col>
                        )}
                        {(props.data.mainpage.country == 'Italy' ||
                          (props.data.mainpage.country == 'Netherlands' &&
                            props.data.mainpage.shipToType.startsWith('Off'))) && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Truck Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.shipto.truckType}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Trailer Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.shipto.trailerType}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                        {props.data.mainpage.country === 'Sweden' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Delivery Window')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.shipto.delivWindow}
                              </div>
                            </div>
                          </Col>
                        )}
                        {props.data.mainpage.country === 'United Kingdom' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Hierarchy type - D Node')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.shipto.hierarchyTypeDNode}
                              </div>
                            </div>
                          </Col>
                        )}
                        {props.data.mainpage.country === 'France' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Attribute Type 7')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.shipto.subChannel}
                              </div>
                            </div>
                          </Col>
                        )}
                        {props.data.mainpage?.country == 'Belgium' &&
                          (props.data.mainpage?.shipToType.startsWith('National') ||
                            props.data.mainpage?.shipToType.startsWith('On-Trade') ||
                            props.data.mainpage?.shipToType.startsWith('Off-Trade') ||
                            props.data.mainpage?.shipToType.endsWith('Indirect')) && (
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Sub Channel')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.shipto?.subChannel}
                                </div>
                              </div>
                            </Col>
                          )}
                        {/* {props.data.mainpage.country == 'Belgium' && props.data.mainpage.shipToType == 'Off-Trade – Direct' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Tier')}</label>
                              <div className="form-control-textfield">{props.data.shipto.tier}</div>
                            </div>
                          </Col>
                        )} */}
                        {(props.data.mainpage.country == 'France' &&
                          ['On-Trade – Direct', 'Wholesale– Direct'].includes(
                            props.data.mainpage.shipToType,
                          )) ||
                          (props.data.mainpage.country == 'United Kingdom' &&
                            props.data.mainpage.shipToType == 'Off-Trade - Retail') ||
                          (props.data.mainpage.country == 'Netherlands' &&
                            props.data.mainpage.shipToType.endsWith('Direct') && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Delivering Plant')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.deliveringPlant}
                                  </div>
                                </div>
                              </Col>
                            ))}
                        {props.data.mainpage.country == 'Germany' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Customer Classification')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.shipto.customerClassification}
                              </div>
                            </div>
                          </Col>
                        )}
                        {props.data.mainpage.country == 'Belgium' &&
                          props.data.mainpage.shipToType == 'Off-Trade – Direct' && (
                            <Row>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Delivery Time')}
                                  </label>
                                </div>
                              </Col>
                              <Row>
                                <Col xs={6} lg={3}>
                                  <div className="form-control-textfield">
                                    <label className="form-label text-left">
                                      {getLang('Monday')}
                                    </label>
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('To: ')}
                                    {props.data.shipto.monMornFrom}
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('From: ')}
                                    {props.data.shipto.monMornTo}
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-control-textfield">
                                    <label className="form-label text-left">
                                      {getLang('Tuesday')}
                                    </label>
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('To: ')}
                                    {props.data.shipto.tueMornFrom}
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('From: ')}
                                    {props.data.shipto.tueMornTo}
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-control-textfield">
                                    <label className="form-label text-left">
                                      {getLang('Wednesday')}
                                    </label>
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('To: ')}
                                    {props.data.shipto.wedMornFrom}
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('From: ')}
                                    {props.data.shipto.wedMornTo}
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-control-textfield">
                                    <label className="form-label text-left">
                                      {getLang('Thursday')}
                                    </label>
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('To: ')}
                                    {props.data.shipto.thuMornFrom}
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('From: ')}
                                    {props.data.shipto.thuMornTo}
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Friday')}
                                    </label>
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('To: ')}
                                    {props.data.shipto.friMornFrom}
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('From: ')}
                                    {props.data.shipto.friMornTo}
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Saturday')}
                                    </label>
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('To: ')}
                                    {props.data.shipto.satMornFrom}
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('From: ')}
                                    {props.data.shipto.satMornTo}
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Sunday')}
                                    </label>
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('To: ')}
                                    {props.data.shipto.sunMornFrom}
                                  </div>
                                  <div className="form-control-textfield">
                                    {getLang('From: ')}
                                    {props.data.shipto.sunMornTo}
                                  </div>
                                </Col>
                              </Row>
                            </Row>
                          )}
                      </Row>

                      <hr />
                      {props.data.mainpage.customerReqType !== 'Ship To' && (
                        <>
                          <Row>
                            <Col xs={6} lg={2}>
                              <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Payer')}</h2>
                            </Col>
                            {props.data.payer.existingPayerID && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Existing Payer ID')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.existingPayerID}
                                  </div>
                                </div>
                              </Col>
                            )}
                          </Row>

                          <Row>
                            <Col sm={12} lg={4}>
                              <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Title')}</label>
                                <div className="form-control-textfield">
                                  {props.data.payer.title}
                                </div>
                              </div>
                            </Col>
                            {/* {props.data.mainpage.country == 'Belgium' && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Does the customer have a VAT number?')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.vatExist}
                                  </div>
                                </div>
                              </Col>
                            )} */}
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Tax Number')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.taxNumber}
                                </div>
                              </div>
                            </Col>
                            {props.data.mainpage.country == 'France' && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Tax Number 2')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.taxNumber2}
                                  </div>
                                </div>
                              </Col>
                            )}
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('VAT Registration No')}.
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.vatRegNumber}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  Legal Entity / {getLang('Name')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.name}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')} 2</label>
                                <div className="form-control-textfield">
                                  {props.data.payer.name2}
                                </div>
                              </div>
                            </Col>
                            {['Italy', 'United Kingdom', 'Netherlands'].includes(
                              props.data.mainpage.country,
                            ) && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {props.data.mainpage.country === 'United Kingdom'
                                      ? getLang('Company number')
                                      : getLang('KVK number')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.companyNumber}
                                  </div>
                                </div>
                              </Col>
                            )}
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Postal Code')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.postalCode}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('House Number')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.houseNumber}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Street')}</label>
                                <div className="form-control-textfield">
                                  {props.data.payer.street}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('City')}</label>
                                <div className="form-control-textfield">
                                  {props.data.payer.city}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Country')}</label>
                                <div className="form-control-textfield">
                                  {props.data.payer.payerCountry}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Region')}</label>
                                <div className="form-control-textfield">
                                  {props.data.payer.region}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Language')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.language}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Reason for Payment Term')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.ReasonPaymentTerm}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('EDI')}</label>
                                <div className="form-control-textfield">
                                  {props.data.payer.ediOption}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('GLN/ILN CODE')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.glnIlnCode}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('EDI Email Address')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.ediEmail}
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={12} lg={4}>
                              <h3 style={{ color: '#e0702f' }}>{getLang('Communication')}</h3>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Telephone/ Fax numbers')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.telephone}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Mobile')}</label>
                                <div className="form-control-textfield">
                                  {props.data.payer.mobile}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('E-mail Address')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.email}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Yearly Volume Forecast')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.yearlyVolForecastHL}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Contact Person')}
                                </label>
                                <div className="form-control-textfield" inputMode="text">
                                  {props.data.payer.contactPerson}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('E-Invoicing')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.eInvOption}
                                </div>
                              </div>
                            </Col>
                            {props.data.payer.eInvOption && (
                              <>
                                {/* <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('E-Invoicing')} Email Type
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.payer.eInvEmailType}
                                    </div>
                                  </div>
                                </Col> */}
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      General/Accounting Email
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.payer.eInvGeneralEmail}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">Logistics Email</label>
                                    <div className="form-control-textfield">
                                      {props.data.payer.eInvLogEmail}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">Invoicing Email</label>
                                    <div className="form-control-textfield">
                                      {props.data.payer.eInvInvEmail}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">Ordering Email</label>
                                    <div className="form-control-textfield">
                                      {props.data.payer.eInvOrderEmail}
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Is Vendor')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.isVendor}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Vendor No.')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.vendorNumber}
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={12} lg={4}>
                              <h3 style={{ color: '#e0702f' }}>
                                {getLang('Bank and Tax Details')}
                              </h3>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('IBAN')}</label>
                                <div className="form-control-textfield">
                                  {props.data.payer.iban}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Account Holder Name')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.accHolderName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Bank Key')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.bankKey}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Bank Account')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.bankAcc}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('SWIFT/ BIC Code')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.swiftBicCode}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Please Attach The Supportings For IBAN & Bank Details')}
                                </label>
                                <div className="form-control-textfield">
                                  {getFileNameRegex.exec(props.data.payer.supportDocsBank)?.length >
                                    0 && (
                                    <a href={props.data.payer.supportDocsBank}>
                                      {getFileNameRegex.exec(props.data.payer.supportDocsBank)[0]}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Attachment 2')}
                                </label>
                                <div className="form-control-textfield">
                                  {getFileNameRegex.exec(props.data.payer.supportDocsBank1)
                                    ?.length > 0 && (
                                    <a href={props.data.payer.supportDocsBank1}>
                                      {getFileNameRegex.exec(props.data.payer.supportDocsBank1)[0]}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  Please Attach other supporting Documents 1
                                </label>
                                <div className="form-control-textfield">
                                  {getFileNameRegex.exec(props.data.payer.supportDocsBank4)
                                    ?.length > 0 && (
                                    <a href={props.data.payer.supportDocsBank2}>
                                      {getFileNameRegex.exec(props.data.payer.supportDocsBank4)[0]}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  Please Attach other supporting Documents 2
                                </label>
                                <div className="form-control-textfield">
                                  {getFileNameRegex.exec(props.data.payer.supportDocsBank5)
                                    ?.length > 0 && (
                                    <a href={props.data.payer.supportDocsBank2}>
                                      {getFileNameRegex.exec(props.data.payer.supportDocsBank5)[0]}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  Please Attach other supporting Documents 3
                                </label>
                                <div className="form-control-textfield">
                                  {getFileNameRegex.exec(props.data.payer.supportDocsBank6)
                                    ?.length > 0 && (
                                    <a href={props.data.payer.supportDocsBank2}>
                                      {getFileNameRegex.exec(props.data.payer.supportDocsBank6)[0]}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </Col>
                            {/* {props.data.mainpage.country == 'Belgium' &&
                              props.data.mainpage.shipToType == 'Off-Trade – Direct' && (
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {'Payment Term approval'}
                                    </label>
                                    <div className="form-control-textfield">
                                      {getFileNameRegex.exec(props.data.payer.supportDocsBank3)
                                        ?.length > 0 && (
                                        <a href={props.data.payer.supportDocsBank3}>
                                          {
                                            getFileNameRegex.exec(
                                              props.data.payer.supportDocsBank3,
                                            )[0]
                                          }
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              )} */}
                          </Row>

                          <Row>
                            <Col sm={12} lg={4}>
                              <h3 style={{ color: '#e0702f' }}>{getLang('Company Data')}</h3>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Terms of payment')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.paymentTerms}
                                </div>
                              </div>
                            </Col>
                            {props.data.mainpage.country == 'Germany' && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">Regulator</label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.regulator}
                                  </div>
                                </div>
                              </Col>
                            )}
                            {props.data.mainpage.country == 'Netherlands' && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Does the customer have rent?')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.rent}
                                  </div>
                                </div>
                              </Col>
                            )}
                            {props.data.mainpage.country == 'Netherlands' && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Payment Method')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.paymentMethod}
                                  </div>
                                </div>
                              </Col>
                            )}
                            {props.data.mainpage.country == 'Netherlands' &&
                              props.data.payer.rent == 'No' && (
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Reason why Direct Debit is not applicable')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.payer.reasonDirectDebit}
                                    </div>
                                  </div>
                                </Col>
                              )}
                            {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">Does the customer have rent?</label>
                            <div className="form-control-textfield">{props.data.payer.customerRent}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">PEC</label>
                            <div className="form-control-textfield">{props.data.payer.pec}</div>
                          </div>
                        </Col> */}
                          </Row>

                          <hr />

                          <Row>
                            <Col sm={12} lg={4}>
                              <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Sold To')}</h2>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={12} lg={4}>
                              <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                            </Col>
                          </Row>
                          {!['Wholesaler - On Trade'].includes(props.data.mainpage.shipToType) && (
                            <Row>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">{getLang('Name')}</label>
                                  <div className="form-control-textfield">
                                    {props.data.mainpage.country == 'Belgium'
                                      ? (props.data.shipto.name + props.data.payer.name).slice(
                                          0,
                                          Math.min(
                                            (props.data.shipto.name + ' ' + props.data.payer.name)
                                              .length,
                                            40,
                                          ),
                                        )
                                      : (props.data.mainpage.country == 'Netherlands' ||
                                          props.data.mainpage.country == 'Italy') &&
                                        props.data.mainpage.customerReqType == 'Prospect'
                                      ? props.data.shipto.name + 'PROSPECT'
                                      : props.data.shipto.name}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Name')} 2
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.mainpage.country == 'Netherlands'
                                      ? props.data.shipto.name2
                                      : ['France', 'United Kingdom', 'Belgium'].includes(
                                          props.data.mainpage.country,
                                        )
                                      ? props.data.shipto.name
                                      : props.data.mainpage.country == 'Germany'
                                      ? props.data.payer.name
                                      : ''}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Postal Code')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.postalCode}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Street')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.street}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('House Number')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.houseNumber}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">{getLang('City')}</label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.city}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Country')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.mainpage.country}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Region')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.regionDesc}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Language')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.language}
                                  </div>
                                </div>
                              </Col>
                              {/* {props.data.shipto.telephoneOption === 'General/Accounting' && ( */}
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Accounting Telephone')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.generalTelephone}
                                  </div>
                                </div>
                              </Col>
                              {/* // )} */}
                              {/* // {props.data.shipto.telephoneOption === 'Logistics' && ( */}
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Logistics Telephone')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.logTelephone}
                                  </div>
                                </div>
                              </Col>
                              {/* // )} */}
                              {/* // {props.data.shipto.telephoneOption === 'Invoicing' && ( */}
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Invoicing Telephone')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.invTelephone}
                                  </div>
                                </div>
                              </Col>
                              {/* // )} */}
                              {/* // {props.data.shipto.telephoneOption === 'Ordering' && ( */}
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Ordering Telephone')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.orderTelephone}
                                  </div>
                                </div>
                              </Col>
                              {/* // )} */}
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Mobile')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.mobile}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('E-mail Address')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.email}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Contact Person')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.contactPerson}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Profit Center')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.profitCenter}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">{getLang('EDI')}</label>
                                  <div className="form-control-textfield">
                                    {props.data.shipto.ediOption}
                                  </div>
                                </div>
                              </Col>
                              {props.data.shipto.ediOption === 'Yes' && (
                                <>
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('GLN/ILN CODE')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.shipto.glnIlnCode}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('EDI Email Address')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.shipto.ediEmail}
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              )}
                            </Row>
                          )}
                          <Row>
                            {props.data.mainpage.country == 'United Kingdom' && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Attribute Type 9')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.attributeType9}
                                </div>
                              </Col>
                            )}

                            {['Netherlands', 'Belgium', 'France', 'Luxembourg'].includes(
                              props.data.mainpage.country,
                            ) && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('VAT Registration Number')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.vatRegNumber}
                                </div>
                              </Col>
                            )}

                            {['Netherlands', 'United Kingdom'].includes(
                              props.data.mainpage.country,
                            ) && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Hierarchy type - A Node')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.hierarchyTypeANode}
                                </div>
                              </Col>
                            )}

                            {props.data.mainpage.country == 'United Kingdom' && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">{getLang('A-Node')}</label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.aNode}
                                </div>
                              </Col>
                            )}

                            {props.data.mainpage.country == 'Netherlands' && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Hierarchy Number')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.aNode}
                                </div>
                              </Col>
                            )}

                            {props.data.mainpage.country == 'Netherlands' && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Drop Size')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.dropSize}
                                </div>
                              </Col>
                            )}

                            {(props.data.mainpage.country == 'Belgium' &&
                              props.data.mainpage.shipToType == 'On-Trade – Direct') ||
                              (['Netherlands'].includes(props.data.mainpage.country) && (
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">
                                    {getLang('Promo News')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.soldto.promoNews}
                                  </div>
                                </Col>
                              ))}

                            {(props.data.mainpage.country == 'Netherlands' ||
                              props.data.mainpage.country == 'Belgium') && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Delivery Day')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.deliveryDay}
                                </div>
                              </Col>
                            )}

                            {props.data.mainpage.country == 'Netherlands' && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('VERWERKINGSTOESLAG')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.verwerkingstoeslag}
                                </div>
                              </Col>
                            )}

                            {props.data.mainpage.country == 'Netherlands' && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Condition Group 2')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.conditionGroup2Desc}
                                </div>
                              </Col>
                            )}
                            {['Netherlands', 'Belgium', 'France'].includes(
                              props.data.mainpage.country,
                            ) && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Customer Group 3')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.customerGroup3Desc}
                                </div>
                              </Col>
                            )}

                            {((props.data.mainpage.country == 'Belgium' &&
                              props.data.mainpage.shipToType == 'On-Trade – Indirect') ||
                              (['Germany'].includes(props.data.mainpage.country) &&
                                props.data.mainpage.shipToType.includes('Indirect')) ||
                              props.data.mainpage.country == 'Netherlands') && (
                              <>
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">{`${
                                    props.data.mainpage.shipToType.includes('Direct')
                                      ? getLang('Attribute 7 / Cellar beer')
                                      : getLang('Use of 2sold-To')
                                  }`}</label>
                                  <div className="form-control-textfield">
                                    {props.data.soldto.att7}
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">
                                    {getLang('2 sold-To Payer Number')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.soldto.soldToPayer2}
                                  </div>
                                </Col>
                              </>
                            )}

                            {(['France', 'Italy'].includes(props.data.mainpage.country) ||
                              (props.data.mainpage.country == 'Germany' &&
                                props.data.mainpage.customerReqType != '2nd Sold To') ||
                              (props.data.mainpage.country == 'Belgium' &&
                                !props.data.mainpage.shipToType.startsWith('Wholesaler'))) && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('INCO Terms 1')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.incoTerms1}
                                </div>
                              </Col>
                            )}

                            {(['France'].includes(props.data.mainpage.country) ||
                              (props.data.mainpage.country == 'Germany' &&
                                props.data.mainpage.customerReqType != '2nd Sold To')) && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('INCO Terms 2')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.incoTerms2}
                                </div>
                              </Col>
                            )}

                            {props.data.mainpage.country == 'Belgium' &&
                              (props.data.mainpage.shipToType.startsWith('National') ||
                                [
                                  'Off-Trade – Direct',
                                  'On-Trade – Direct',
                                  'On-Trade – Indirect',
                                ].includes(props.data.mainpage.shipToType)) && (
                                <>
                                  {/* <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Pricing Hierarchy')}
                                      {'?'}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.pricingHierarchyOpt}
                                    </div>
                                  </Col> */}
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Pricing Hierarchy')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.pricingHierarchy}
                                    </div>
                                  </Col>
                                </>
                              )}

                            {props.data.mainpage.country == 'Belgium' &&
                              props.data.mainpage.shipToType.startsWith('Wholesaler') && (
                                <>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Attribute 7 / Cellar beer')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.att7}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Name')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.name}
                                    </div>
                                  </Col>
                                  {[
                                    'K&PD_ALL_POCM (Name Merger)',
                                    'K&PD_ALL_POCM_3PP (Name Merger)',
                                  ].includes(props.data.soldto.name) && (
                                    <Col xs={6} lg={3}>
                                      <label className="form-label text-left">
                                        {getLang('Merger')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.soldto.merger}
                                      </div>
                                    </Col>
                                  )}
                                  {['BC_ALL (Name Buying Group)'].includes(
                                    props.data.soldto.name,
                                  ) && (
                                    <Col xs={6} lg={3}>
                                      <label className="form-label text-left">
                                        {getLang('Buying Group')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.soldto.buyingGroup}
                                      </div>
                                    </Col>
                                  )}
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Postal Code')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.postalCode}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('House Number')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.houseNumber}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Street')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.street}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('City')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.city}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Country')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.soldCountry}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Region')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.region}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Language')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.language}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Telephone/Fax numbers')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.telephone}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('Mobile')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.mobile}
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('E-mail Address')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.soldto.email}
                                    </div>
                                  </Col>
                                </>
                              )}

                            {([
                              'Austria',
                              'Denmark',
                              'Finland',
                              'Italy',
                              'Norway',
                              'Poland',
                              'Sweden',
                              'Switzerland',
                            ].includes(props.data.mainpage.country) ||
                              (props.data.mainpage.country == 'Belgium' &&
                                props.data.mainpage.shipToType == 'Off-Trade – Direct')) && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Shipping Conditions')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.soldto.shippingCondition}
                                </div>
                              </Col>
                            )}

                            {([
                              'Austria',
                              'Denmark',
                              'Finland',
                              'France',
                              'Norway',
                              'Poland',
                              'Sweden',
                              'Switzerland',
                            ].includes(props.data.mainpage.country) ||
                              (props.data.mainpage.country == 'Germany' &&
                                props.data.mainpage.customerReqType != '2nd Sold To')) && (
                              <>
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">
                                    {getLang('Price List')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.soldto.priceList}
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">{getLang('EDI')}</label>
                                  <div className="form-control-textfield">
                                    {props.data.soldto.ediOption}
                                  </div>
                                </Col>
                                {props.data.soldto.ediOption == 'Yes' && (
                                  <>
                                    <Col xs={6} lg={3}>
                                      <label className="form-label text-left">
                                        {getLang('GLN/ILN CODE')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.soldto.glnIlnCode}
                                      </div>
                                    </Col>
                                    <Col xs={6} lg={3}>
                                      <label className="form-label text-left">
                                        {getLang('EDI Type')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.soldto.ediType}
                                      </div>
                                    </Col>
                                  </>
                                )}
                              </>
                            )}
                          </Row>

                          <hr />

                          <Row>
                            <Col xs={6} lg={2}>
                              <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Bill To')}</h2>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Option for Bill to')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.billto.showBillTo}
                                </div>
                              </div>
                            </Col>
                            {props.data.billto.showBillTo === 'Yes-Fill details' && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Existing Bill To ID')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.existingBillToID}
                                  </div>
                                </div>
                              </Col>
                            )}
                          </Row>

                          {props.data.billto.showBillTo === 'Yes-Fill details' && (
                            <>
                              <Row>
                                <Col sm={12} lg={4}>
                                  <h3 style={{ color: '#e0702f' }}>{getLang('Bill To')}</h3>
                                </Col>
                              </Row>

                              <Row>
                                {props.data.mainpage.country != 'United Kingdom' && (
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('Title')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.billto.title}
                                      </div>
                                    </div>
                                  </Col>
                                )}
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Name')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.name}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Postal Code')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.postalCode}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('House Number')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.houseNumber}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Street')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.street}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('City')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.city}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Country')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.billCountry}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Region')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.region}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Language')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.language}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Telephone')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.telephone}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Mobile')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.mobile}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('E-mail Address')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.emailAddress}
                                    </div>
                                  </div>
                                </Col>
                                {/* <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('E-Invoicing')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.eInvOption}
                                    </div>
                                  </div>
                                </Col> */}
                                {props.data.billto.eInvOption && (
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('E-mail address (E-invoicing)')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.billto.eInvEmail}
                                      </div>
                                    </div>
                                  </Col>
                                )}
                                {['Netherlands', 'Belgium', 'France', 'Luxembourg'].includes(
                                  props.data.mainpage.country,
                                ) && (
                                  <Col xs={6} lg={3}>
                                    <label className="form-label text-left">
                                      {getLang('VAT Registration Number')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.vatRegNumber}
                                    </div>
                                  </Col>
                                )}
                                {props.data.mainpage.country == 'France' && (
                                  <>
                                    <Col xs={6} lg={3}>
                                      <div className="form-group">
                                        <label className="form-label text-left">
                                          {getLang('Tax Number')}
                                        </label>
                                        <div className="form-control-textfield">
                                          {props.data.payer.taxNumber}
                                        </div>
                                      </div>
                                    </Col>

                                    <Col xs={6} lg={3}>
                                      <div className="form-group">
                                        <label className="form-label text-left">
                                          {getLang('Tax Number 2')}
                                        </label>
                                        <div className="form-control-textfield">
                                          {props.data.payer.taxNumber2}
                                        </div>
                                      </div>
                                    </Col>

                                    <Col xs={6} lg={3}>
                                      <div className="form-group">
                                        <label className="form-label text-left">
                                          {getLang('EDI')}
                                        </label>
                                        <div className="form-control-textfield">
                                          {props.data.billto.ediOption}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xs={6} lg={3}>
                                      <div className="form-group">
                                        <label className="form-label text-left">
                                          {getLang('GLN/ILN CODE')}
                                        </label>
                                        <div className="form-control-textfield">
                                          {props.data.billto.glnIlnCode}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xs={6} lg={3}>
                                      <div className="form-group">
                                        <label className="form-label text-left">
                                          {getLang('EDI Email Address')}
                                        </label>
                                        <div className="form-control-textfield">
                                          {props.data.billto.ediEmail}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xs={6} lg={3}>
                                      <div className="form-group">
                                        <label className="form-label text-left">
                                          {getLang('EDI Type')}
                                        </label>
                                        <div className="form-control-textfield">
                                          {props.data.billto.ediType}
                                        </div>
                                      </div>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {props.data.mainpage.customerReqType == 'Internal Customer' && (
                <>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor_EmailID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.Requestor_EmailID}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr />

                      <Row>
                        {/* <Col xs={6} lg={2}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Plant Data')}</h2>
                        </Col> */}
                        {/* <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Reference ID')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.plant.existingID}
                              </div>
                            </div>
                          </Col> */}
                      </Row>

                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}2</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.name2}
                            </div>
                          </div>
                        </Col>
                        {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('VAT Registration No')}</label>
                            <div className="form-control-textfield">{props.data.profileData.vatRegNumber}</div>
                          </div>
                        </Col> */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.houseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.city}
                            </div>
                          </div>
                        </Col>
                        {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">{props.data.mainpage.country}</div>
                          </div>
                        </Col> */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.region}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Language')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.language}
                            </div>
                          </div>
                        </Col>
                        {/* {props.data.shipto.telephoneOption === 'General/Accounting' && ( */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Telephone')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.telephone}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Mobile')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.mobile}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.email}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Contact Person')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.contactPerson}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Profit Center')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.profitCenter}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Sales Data')}</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivering Plant')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.deliveringPlant}
                            </div>
                          </div>
                        </Col>
                        {props.data.mainpage.country === 'Italy' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Truck Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.profileData.truckType}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Trailer Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.profileData.trailerType}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              {props.data.mainpage.customerReqType == 'NL Customer' && (
                <Row>
                  <Col lg={12}>
                    <Row>
                      <Col sm={12} lg={4}>
                        <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Country')}</label>
                          <div className="form-control-textfield">
                            {props.data.mainpage.country}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Requestor_EmailID')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.mainpage.Requestor_EmailID}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col xs={6} lg={2}>
                        <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Ship To')}</h2>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} lg={4}>
                        <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')}</label>
                          <div className="form-control-textfield">{props.data.shipto.name}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')} 2</label>
                          <div className="form-control-textfield">{props.data.shipto.name2}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Postal Code')}</label>
                          <div className="form-control-textfield">
                            {props.data.shipto.postalCode}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Street')}</label>
                          <div className="form-control-textfield">{props.data.shipto.street}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('House Number')}</label>
                          <div className="form-control-textfield">
                            {props.data.shipto.houseNumber}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('City')}</label>
                          <div className="form-control-textfield">{props.data.shipto.city}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Country')}</label>
                          <div className="form-control-textfield">
                            {props.data.mainpage.country}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Region')}</label>
                          <div className="form-control-textfield">{props.data.shipto.region}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Language')}</label>
                          <div className="form-control-textfield">{props.data.shipto.language}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Mobile')}</label>
                          <div className="form-control-textfield">{props.data.shipto.mobile}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('E-mail Address')}
                          </label>
                          <div className="form-control-textfield">{props.data.shipto.email}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Contact Person')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.shipto.contactPerson}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <hr />

                    <>
                      <Row>
                        <Col xs={6} lg={2}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Payer')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">{props.data.payer.name}</div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Search Term ')} 2
                            </label>
                            <div className="form-control-textfield">
                              {props.data.payer.searchTerm2}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.payer.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.payer.houseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">{props.data.payer.street}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">{props.data.payer.city}</div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Language')}</label>
                            <div className="form-control-textfield">
                              {props.data.payer.language}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Accounting Clerk')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.payer.acctgClerk}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr />

                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Sold To')}</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <label className="form-label text-left">{getLang('Search Term')} 2</label>
                          <div className="form-control-textfield">
                            {props.data.soldto.searchTerm2}
                          </div>
                        </Col>
                      </Row>
                    </>
                  </Col>
                </Row>
              )}
              {props.data.mainpage.customerReqType == 'Inter Company Customer' && (
                <>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor_EmailID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.Requestor_EmailID}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Company Code')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.companyCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Sales Org')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.salesOrg}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Division')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.division}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}2</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.name2}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.houseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.region}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attribute Type 5')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.attributeType5}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attribute Type 8')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.attributeType8}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attribute Type 10')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.attributeType10}
                            </div>
                          </div>
                        </Col>
                        {/* {props.data.shipto.telephoneOption === 'General/Accounting' && ( */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Trading Partner')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.tradingPartner}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Vendor Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.vendorNum}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('VAT Registration No')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.vatRegNumber}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Bank and Tax Details')}</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('IBAN')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.iban}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Account Holder Name')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.accHolderName}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Bank Key')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.bankKey}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Bank Account')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.bankAcc}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('SWIFT/ BIC Code')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.swiftBicCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Please Attach The Supportings For IBAN & Bank Details')}
                            </label>
                            <div className="form-control-textfield">
                              {getFileNameRegex.exec(props.data.profileData.supportDocsBank)
                                ?.length > 0 && (
                                <a href={`${props.data.payer.supportDocsBank}}`}>
                                  {getFileNameRegex.exec(props.data.profileData.supportDocsBank)[0]}
                                </a>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 1')}
                            </label>
                            <div className="form-control-textfield">
                              {getFileNameRegex.exec(props.data.profileData.supportDocsBank1)
                                ?.length > 0 && (
                                <a href={props.data.profileData.supportDocsBank1}>
                                  {
                                    getFileNameRegex.exec(
                                      props.data.profileData.supportDocsBank1,
                                    )[0]
                                  }
                                </a>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 2')}
                            </label>
                            <div className="form-control-textfield">
                              {getFileNameRegex.exec(props.data.profileData.supportDocsBank2)
                                ?.length > 0 && (
                                <a href={props.data.profileData.supportDocsBank2}>
                                  {
                                    getFileNameRegex.exec(
                                      props.data.profileData.supportDocsBank2,
                                    )[0]
                                  }
                                </a>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Sales Data')}</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Shipping Condition')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.shippingCondition}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivering Plant')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.deliveringPlant}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Price List')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.price}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Company Data')}</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Recon Account')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.reconAcc}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Terms of payment')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.paymentTerms}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              {props.data.mainpage.customerReqType == 'Forward Agent Customer' && (
                <>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor_EmailID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.mainpage.Requestor_EmailID}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}2</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.name2}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('VAT Registration No')}.
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.vatRegNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.houseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.regionDesc}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Language')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.language}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Telephone')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.telephone}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Mobile')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.mobile}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.email}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Bank  Details')}</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('IBAN')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.iban}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Account Holder Name')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.accHolderName}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Sales Data')}</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Price List')}</label>
                            <div className="form-control-textfield">
                              {props.data.profileData.price}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Shipping Condition')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.shippingCondition}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivering Plant')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.deliveringPlant}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Recon Account')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.reconAcc}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Terms of payment')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.profileData.paymentTerms}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              {props.data.mainpage.customerReqType == 'Vendor Customer' && (
                <Row>
                  <Col lg={12}>
                    <Row>
                      <Col sm={12} lg={4}>
                        <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Country')}</label>
                          <div className="form-control-textfield">
                            {props.data.mainpage.country}
                          </div>
                        </div>
                      </Col>

                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Ship-to Type')}</label>
                          <div className="form-control-textfield">
                            {props.data.mainpage.shipToType}
                          </div>
                        </div>
                      </Col>

                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Requestor_EmailID')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.mainpage.Requestor_EmailID}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col xs={6} lg={2}>
                        <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Ship To')}</h2>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} lg={4}>
                        <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')}</label>
                          <div className="form-control-textfield">{props.data.shipto.name}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Postal Code')}</label>
                          <div className="form-control-textfield">
                            {props.data.shipto.postalCode}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Street')}</label>
                          <div className="form-control-textfield">{props.data.shipto.street}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('House Number')}</label>
                          <div className="form-control-textfield">
                            {props.data.shipto.houseNumber}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('City')}</label>
                          <div className="form-control-textfield">{props.data.shipto.city}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Country')}</label>
                          <div className="form-control-textfield">
                            {props.data.mainpage.country}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Region')}</label>
                          <div className="form-control-textfield">{props.data.shipto.region}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Language')}</label>
                          <div className="form-control-textfield">{props.data.shipto.language}</div>
                        </div>
                      </Col>
                      {/* {props.data.shipto.telephoneOption === 'General/Accounting' && ( */}
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Accounting Telephone')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.shipto.generalTelephone}
                          </div>
                        </div>
                      </Col>
                      {/* // )} */}
                      {/* // {props.data.shipto.telephoneOption === 'Logistics' && ( */}
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Logistics Telephone')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.shipto.logTelephone}
                          </div>
                        </div>
                      </Col>
                      {/* // )} */}
                      {/* // {props.data.shipto.telephoneOption === 'Invoicing' && ( */}
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Invoicing Telephone')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.shipto.invTelephone}
                          </div>
                        </div>
                      </Col>
                      {/* // )} */}
                      {/* // {props.data.shipto.telephoneOption === 'Ordering' && ( */}
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Ordering Telephone')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.shipto.orderTelephone}
                          </div>
                        </div>
                      </Col>
                      {/* // )} */}
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Mobile')}</label>
                          <div className="form-control-textfield">{props.data.shipto.mobile}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('E-mail Address')}
                          </label>
                          <div className="form-control-textfield">{props.data.shipto.email}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Contact Person')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.shipto.contactPerson}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} lg={4}>
                        <h3 style={{ color: '#e0702f' }}>{getLang('Sales Data')}</h3>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Segment')}</label>
                          <div className="form-control-textfield">{props.data.shipto.segment}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Sub Segment')}</label>
                          <div className="form-control-textfield">
                            {props.data.shipto.subSegment}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Excise duty type')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.shipto.exciseDutyType}
                          </div>
                        </div>
                      </Col>

                      {props.data.mainpage.country === 'France' && (
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attribute Type 7')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.shipto.subChannel}
                            </div>
                          </div>
                        </Col>
                      )}
                      {props.data.mainpage?.country == 'Belgium' &&
                        (props.data.mainpage?.shipToType.startsWith('National') ||
                          props.data.mainpage?.shipToType.startsWith('On-Trade') ||
                          props.data.mainpage?.shipToType.startsWith('Off-Trade') ||
                          props.data.mainpage?.shipToType.endsWith('Indirect')) && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Sub Channel')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.shipto?.subChannel}
                              </div>
                            </div>
                          </Col>
                        )}
                    </Row>

                    <hr />
                    {props.data.mainpage.customerReqType !== 'Ship To' && (
                      <>
                        <Row>
                          <Col xs={6} lg={2}>
                            <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Payer')}</h2>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} lg={4}>
                            <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Title')}</label>
                              <div className="form-control-textfield">{props.data.payer.title}</div>
                            </div>
                          </Col>
                          {/* {props.data.mainpage.country == 'Belgium' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Does the customer have a VAT number?')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.vatExist}
                              </div>
                            </div>
                          </Col>
                        )} */}
                          {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Tax Number')}</label>
                            <div className="form-control-textfield">
                              {props.data.payer.taxNumber}
                            </div>
                          </div>
                        </Col> */}
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('VAT Registration No')}.
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.vatRegNumber}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Name')}</label>
                              <div className="form-control-textfield">{props.data.payer.name}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Postal Code')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.postalCode}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('House Number')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.houseNumber}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Street')}</label>
                              <div className="form-control-textfield">
                                {props.data.payer.street}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('City')}</label>
                              <div className="form-control-textfield">{props.data.payer.city}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Country')}</label>
                              <div className="form-control-textfield">
                                {props.data.payer.payerCountry}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Region')}</label>
                              <div className="form-control-textfield">
                                {props.data.payer.region}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Language')}</label>
                              <div className="form-control-textfield">
                                {props.data.payer.language}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} lg={4}>
                            <h3 style={{ color: '#e0702f' }}>{getLang('Communication')}</h3>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Telephone/ Fax numbers')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.telephone}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Mobile')}</label>
                              <div className="form-control-textfield">
                                {props.data.payer.mobile}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('E-mail Address')}
                              </label>
                              <div className="form-control-textfield">{props.data.payer.email}</div>
                            </div>
                          </Col>
                          {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Yearly Volume Forecast')}</label>
                        <div className="form-control-textfield">{props.data.payer.yearlyVolForecastHL}</div>
                      </div>
                    </Col> */}
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Contact Person')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.contactPerson}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('E-Invoicing')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.eInvOption}
                              </div>
                            </div>
                          </Col>
                          {props.data.payer.eInvOption && (
                            <>
                              {/* <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('E-Invoicing')} Email Type
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payer.eInvEmailType}
                                </div>
                              </div>
                            </Col> */}
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    General/Accounting Email
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.eInvGeneralEmail}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">Logistics Email</label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.eInvLogEmail}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">Invoicing Email</label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.eInvInvEmail}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">Ordering Email</label>
                                  <div className="form-control-textfield">
                                    {props.data.payer.eInvOrderEmail}
                                  </div>
                                </div>
                              </Col>
                            </>
                          )}
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Is Vendor')}</label>
                              <div className="form-control-textfield">
                                {props.data.payer.isVendor}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Vendor No.')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.vendorNumber}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} lg={4}>
                            <h3 style={{ color: '#e0702f' }}>{getLang('Bank and Tax Details')}</h3>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('IBAN')}</label>
                              <div className="form-control-textfield">{props.data.payer.iban}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Account Holder Name')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.accHolderName}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Bank Key')}</label>
                              <div className="form-control-textfield">
                                {props.data.payer.bankKey}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Bank Account')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.bankAcc}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('SWIFT/ BIC Code')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.swiftBicCode}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Please Attach The Supportings For IBAN & Bank Details')}
                              </label>
                              <div className="form-control-textfield">
                                {getFileNameRegex.exec(props.data.payer.supportDocsBank)?.length >
                                  0 && (
                                  <a href={props.data.payer.supportDocsBank}>
                                    {getFileNameRegex.exec(props.data.payer.supportDocsBank)[0]}
                                  </a>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Attachment 2')}
                              </label>
                              <div className="form-control-textfield">
                                {getFileNameRegex.exec(props.data.payer.supportDocsBank1)?.length >
                                  0 && (
                                  <a href={props.data.payer.supportDocsBank1}>
                                    {getFileNameRegex.exec(props.data.payer.supportDocsBank1)[0]}
                                  </a>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Attachment 3')}
                              </label>
                              <div className="form-control-textfield">
                                {getFileNameRegex.exec(props.data.payer.supportDocsBank2)?.length >
                                  0 && (
                                  <a href={props.data.payer.supportDocsBank2}>
                                    {getFileNameRegex.exec(props.data.payer.supportDocsBank2)[0]}
                                  </a>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} lg={4}>
                            <h3 style={{ color: '#e0702f' }}>{getLang('Company Data')}</h3>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Terms of payment')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payer.paymentTerms}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <hr />

                        <Row>
                          <Col sm={12} lg={4}>
                            <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Sold To')}</h2>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} lg={4}>
                            <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Name')}</label>
                              <div className="form-control-textfield">{props.data.soldto.name}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Postal Code')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.soldto.postalCode}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('House Number')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.soldto.houseNumber}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Street')}</label>
                              <div className="form-control-textfield">
                                {props.data.soldto.street}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('City')}</label>
                              <div className="form-control-textfield">{props.data.soldto.city}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Country')}</label>
                              <div className="form-control-textfield">
                                {props.data.soldto.soldCountry}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Region')}</label>
                              <div className="form-control-textfield">
                                {props.data.soldto.region}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Language')}</label>
                              <div className="form-control-textfield">
                                {props.data.soldto.language}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Telephone/Fax numbers')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.soldto.telephone}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Mobile')}</label>
                              <div className="form-control-textfield">
                                {props.data.soldto.mobile}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('E-mail Address')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.soldto.email}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <hr />

                        <Row>
                          <Col xs={6} lg={2}>
                            <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Bill To')}</h2>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Option for Bill to')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.billto.showBillTo}
                              </div>
                            </div>
                          </Col>
                          {props.data.billto.showBillTo === 'Yes-Fill details' && (
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Bill To ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.billto.existingBillToID}
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>

                        {props.data.billto.showBillTo === 'Yes-Fill details' && (
                          <>
                            <Row>
                              <Col sm={12} lg={4}>
                                <h3 style={{ color: '#e0702f' }}>{getLang('Bill To')}</h3>
                              </Col>
                            </Row>

                            <Row>
                              {props.data.mainpage.country != 'United Kingdom' && (
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Title')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.title}
                                    </div>
                                  </div>
                                </Col>
                              )}
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">{getLang('Name')}</label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.name}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Postal Code')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.postalCode}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('House Number')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.houseNumber}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Street')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.street}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">{getLang('City')}</label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.city}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Country')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.billCountry}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Region')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.region}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Language')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.language}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Telephone')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.telephone}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('Mobile')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.mobile}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('E-mail Address')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.emailAddress}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('E-Invoicing')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {props.data.billto.eInvOption}
                                  </div>
                                </div>
                              </Col>
                              {props.data.billto.eInvOption && (
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('E-mail address (E-invoicing)')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.billto.eInvEmail}
                                    </div>
                                  </div>
                                </Col>
                              )}
                              {props.data.mainpage.country == 'France' && (
                                <>
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('EDI')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.billto.ediOption}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('GLN/ILN CODE')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.billto.glnIlnCode}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('EDI Email Address')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.billto.ediEmail}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('EDI Type')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.billto.ediType}
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
          {props.data.requestType == 'Modification' &&
            props.data.customerReqType != 'Block/Unblock' && (
              <>
                {props.data.mainPage.reqType == 'Block/Unblock' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Block/Unblock')}</h2>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                      </Row>

                      {props.data.blockUnblock.checkShipTo == 'Y' && (
                        <>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang(props.data.blockUnblock.blockUnblockOpt)}{' '}
                                  {getLang('Ship-to')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.checkShipTo}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Ship-to ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.existingShipToID}
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.nameShipTo}
                                </div>
                              </div>
                            </Col>
                            {props.data.blockUnblock.blockUnblockOpt == 'Block' && (
                              <>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Order Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.orderBlockShipTo}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Delivery Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.deliveryBlockShipTo}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Billing Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.billingBlockShipTo}
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                        </>
                      )}

                      {props.data.blockUnblock.checkPayer == 'Y' && (
                        <>
                          <hr />
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang(props.data.blockUnblock.blockUnblockOpt)}{' '}
                                  {getLang('Payer')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.checkPayer}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Payer ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.existingPayerID}
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.namePayer}
                                </div>
                              </div>
                            </Col>
                            {props.data.blockUnblock.blockUnblockOpt == 'Block' && (
                              <>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Order Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.orderBlockPayer}
                                    </div>
                                  </div>
                                </Col>
                                {!['France', 'Belgium'].includes(props.data.mainPage.country) && (
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('Delivery Block Type')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.blockUnblock.deliveryBlockPayer}
                                      </div>
                                    </div>
                                  </Col>
                                )}
                                {!['France', 'Belgium'].includes(props.data.mainPage.country) && (
                                  <Col xs={6} lg={3}>
                                    <div className="form-group">
                                      <label className="form-label text-left">
                                        {getLang('Billing Block Type')}
                                      </label>
                                      <div className="form-control-textfield">
                                        {props.data.blockUnblock.billingBlockPayer}
                                      </div>
                                    </div>
                                  </Col>
                                )}
                              </>
                            )}
                          </Row>
                        </>
                      )}

                      {props.data.blockUnblock.checkSoldTo == 'Y' && (
                        <>
                          <hr />
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang(props.data.blockUnblock.blockUnblockOpt)}{' '}
                                  {getLang('Sold To')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.checkSoldTo}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Sold To ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.existingSoldToID}
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.nameSoldTo}
                                </div>
                              </div>
                            </Col>
                            {props.data.blockUnblock.blockUnblockOpt == 'Block' && (
                              <>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Order Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.orderBlockSoldTo}
                                    </div>
                                  </div>
                                </Col>
                                {props.data.mainPage.country != 'Netherlands' && (
                                  <>
                                    {!['France', 'Belgium'].includes(
                                      props.data.mainPage.country,
                                    ) && (
                                      <Col xs={6} lg={3}>
                                        <div className="form-group">
                                          <label className="form-label text-left">
                                            {getLang('Delivery Block Type')}
                                          </label>
                                          <div className="form-control-textfield">
                                            {props.data.blockUnblock.deliveryBlockSoldTo}
                                          </div>
                                        </div>
                                      </Col>
                                    )}
                                    {!['France', 'Belgium'].includes(
                                      props.data.mainPage.country,
                                    ) && (
                                      <Col xs={6} lg={3}>
                                        <div className="form-group">
                                          <label className="form-label text-left">
                                            {getLang('Billing Block Type')}
                                          </label>
                                          <div className="form-control-textfield">
                                            {props.data.blockUnblock.billingBlockSoldTo}
                                          </div>
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {props.data.mainPage.country == 'Netherlands' &&
                              props.data.blockUnblock.blockUnblockOpt == 'Unblock' && (
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Please Attach The Supportings For KVK')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {getFileNameRegex.exec(
                                        props.data.blockUnblock.supportDocsBank,
                                      )?.length > 0 && (
                                        <a href={props.data.blockUnblock.supportDocsBank}>
                                          {
                                            getFileNameRegex.exec(
                                              props.data.blockUnblock.supportDocsBank,
                                            )[0]
                                          }
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              )}
                          </Row>
                        </>
                      )}

                      {props.data.blockUnblock.checkBillTo == 'Y' && (
                        <>
                          <hr />
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang(props.data.blockUnblock.blockUnblockOpt)}{' '}
                                  {getLang('Bill To')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.checkBillTo}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Bill To ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.existingBillToID}
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.nameBillTo}
                                </div>
                              </div>
                            </Col>
                            {props.data.blockUnblock.blockUnblockOpt == 'Block' && (
                              <>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Order Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.orderBlockBillTo}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Delivery Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.deliveryBlockBillTo}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Billing Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.billingBlockBillTo}
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Ship-to & Sold To Modification' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Ship-to & Sold To Modification')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Sold To ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.nameDelivPlantUpdate.existingSoldToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-To ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.nameDelivPlantUpdate.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield" style={{ color: 'red' }}>
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.nameDelivPlantUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivering Plant')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.nameDelivPlantUpdate.deliveringPlantDesc}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainPage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Telephone')}</label>
                            <div className="form-control-textfield">
                              {props.data.nameDelivPlantUpdate.telephone}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Mobile')}</label>
                            <div className="form-control-textfield">
                              {props.data.nameDelivPlantUpdate.mobile}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.nameDelivPlantUpdate.email}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Shipping Condition')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.nameDelivPlantUpdate.shippingConditionDesc}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('POC Supplier')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.nameDelivPlantUpdate.pocSupplier}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'IBAN change/update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('IBAN change/update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.ibanUpdate.existingPayerID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.ibanUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainPage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('IBAN')}</label>
                            <div className="form-control-textfield">
                              {props.data.ibanUpdate.iban}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Update Type')}</label>
                            <div className="form-control-textfield">
                              {props.data.ibanUpdate.ibanOpt}
                            </div>
                          </div>
                        </Col>
                        {!['France', 'Netherlands'].includes(props.data.mainPage.country) && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('BK Type')}</label>
                              <div className="form-control-textfield">
                                {props.data.ibanUpdate.bkType}
                              </div>
                            </div>
                          </Col>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Account Holder Name')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.ibanUpdate.accHolderName}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Please Attach The Supportings For IBAN & Bank Details')}
                            </label>
                            <div className="form-control-textfield">
                              {getFileNameRegex.exec(props.data.ibanUpdate.supportDocsBank)
                                ?.length > 0 && (
                                <a href={props.data.ibanUpdate.supportDocsBank}>
                                  {getFileNameRegex.exec(props.data.ibanUpdate.supportDocsBank)[0]}
                                </a>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Payer E-Invoicing update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Payer E-Invoicing update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.eInvPayerUpdate.existingPayerID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.eInvPayerUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainPage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Contact Name')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.eInvPayerUpdate.contactName}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Function')}</label>
                            <div className="form-control-textfield">
                              {props.data.eInvPayerUpdate.function}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Contact Email')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.eInvPayerUpdate.contactEmail}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Update Type')}</label>
                            <div className="form-control-textfield">
                              {props.data.eInvPayerUpdate.eInvPayerOpt}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Bill-To E-Invoicing update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Bill-To E-Invoicing Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Bill To ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.eInvBillUpdate.existingBillToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.eInvBillUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainPage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Contact Name')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.eInvBillUpdate.contactName}
                            </div>
                          </div>
                        </Col>
                        {props.data.mainPage.country != 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Function')}</label>
                              <div className="form-control-textfield">
                                {props.data.eInvBillUpdate.function}
                              </div>
                            </div>
                          </Col>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Contact Email')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.eInvBillUpdate.contactEmail}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Update Type')}</label>
                            <div className="form-control-textfield">
                              {props.data.eInvBillUpdate.eInvBillOpt}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Address Modification' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Payer Modification')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.existingPayerID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')} 2</label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.name2}
                            </div>
                          </div>
                        </Col>
                        {props.data.mainPage.country != 'France' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {props.data.mainPage.country == 'United Kingdom'
                                  ? getLang('Company number')
                                  : props.data.mainPage.country == 'Netherlands'
                                  ? getLang('KVK number')
                                  : `${getLang('Name')} 3`}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.payerUpdate.name3}
                              </div>
                            </div>
                          </Col>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.houseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainPage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')}</label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.region}
                            </div>
                          </div>
                        </Col>
                        {['Netherlands', 'Germany'].includes(props.data.mainPage.country) && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('PO Box')}</label>
                                <div className="form-control-textfield">
                                  {props.data.payerUpdate.poBox}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('PO Box Postal Code')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.payerUpdate.poBoxPostalCode}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Telephone')}</label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.telephone}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Mobile')}</label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.mobile}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.payerUpdate.email}
                            </div>
                          </div>
                        </Col>
                        {['Belgium', 'France', 'Netherlands'].includes(
                          props.data.mainPage.country,
                        ) && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {props.data.mainPage.country == 'France'
                                  ? getLang('Please attach a screenshot of www.societe.com')
                                  : getLang('Please Attach The Supportings For KVK')}
                              </label>
                              <div className="form-control-textfield">
                                {getFileNameRegex.exec(props.data.payerUpdate.supportDocsBank)
                                  ?.length > 0 && (
                                  <a href={props.data.payerUpdate.supportDocsBank}>
                                    {
                                      getFileNameRegex.exec(
                                        props.data.payerUpdate.supportDocsBank,
                                      )[0]
                                    }
                                  </a>
                                )}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Others - GLN/EDI Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Others - GLN/EDI Update')}
                          </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <hr />
                      {props.data.othersGlnUpdate.existingShipToID != '' && (
                        <>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Ship-to ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.existingShipToID}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.shipToName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Ship To GLN')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.glnShipTo}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      )}
                      {props.data.othersGlnUpdate.existingSoldToID != '' && (
                        <>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Sold To ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.existingSoldToID}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.soldToName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Sold To GLN')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.glnSoldTo}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      )}
                      {props.data.othersGlnUpdate.existingPayerID != '' && (
                        <>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Payer ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.existingPayerID}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.payerName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Payer GLN')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.glnPayer}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'VAT & Tax Number update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('VAT Tax Number')} {getLang('Modification')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Sold To ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.vatTaxUpdate.existingSoldToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.vatTaxUpdate.existingPayerID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.vatTaxUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Payer Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.vatTaxUpdate.payerName}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainPage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('VAT Registration No')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.vatTaxUpdate.vatRegNumber}
                            </div>
                          </div>
                        </Col>
                        {props.data.mainPage.country != 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Tax Number')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.vatTaxUpdate.taxNumber}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Others - Warehouse Number' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Others - Warehouse Number')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersWarehouseUpdate.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.othersWarehouseUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Warehouse Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersWarehouseUpdate.warehouseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Customer Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersWarehouseUpdate.warehouseCustomerNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('ED Type')}</label>
                            <div className="form-control-textfield">
                              {props.data.othersWarehouseUpdate.warehouseEdTypeDesc}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Company Code')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersWarehouseUpdate.warehouseCompanyCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Valid From')}</label>
                            <div className="form-control-textfield">{`${props.data.othersWarehouseUpdate.warehouseValidFrom.slice(
                              8,
                              10,
                            )}-${props.data.othersWarehouseUpdate.warehouseValidFrom.slice(
                              5,
                              7,
                            )}-${props.data.othersWarehouseUpdate.warehouseValidFrom.slice(
                              0,
                              4,
                            )}`}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Valid To')}</label>
                            <div className="form-control-textfield">{`${props.data.othersWarehouseUpdate.warehouseValidTo.slice(
                              8,
                              10,
                            )}-${props.data.othersWarehouseUpdate.warehouseValidTo.slice(
                              5,
                              7,
                            )}-${props.data.othersWarehouseUpdate.warehouseValidTo.slice(
                              0,
                              4,
                            )}`}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Tax Warehouse Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersWarehouseUpdate.taxWarehouseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('External ED Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersWarehouseUpdate.warehouseExternalEdNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Partner Type')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersWarehouseUpdate.warehousePartnerType}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Others - Text Update Sold To' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Others - Text Update Sold To')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersTextUpdate.existingSoldToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.othersTextUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivery Day')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersTextUpdate.deliveryDay}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Driver Text')}</label>
                            <div className="form-control-textfield">
                              {props.data.othersTextUpdate.driverText}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Invoicing Text')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersTextUpdate.invText}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Others - GLN/EDI Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Others - GLN/EDI Update')}
                          </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      {props.data.othersGlnUpdate.existingShipToID != '' && (
                        <>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Ship-to ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.existingShipToID}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.shipToName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Ship To GLN')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.glnShipTo}
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <hr />
                        </>
                      )}

                      {props.data.othersGlnUpdate.existingSoldToID != '' && (
                        <>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Sold To ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.existingSoldToID}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.soldToName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Sold To GLN')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.glnSoldTo}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      )}

                      {props.data.othersGlnUpdate.existingPayerID != '' && (
                        <>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Existing Payer ID')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.existingPayerID}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.payerName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Payer GLN')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.glnPayer}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      {/* <Row>
                        {props.data.othersGlnUpdate.existingShipToID != '' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Existing ShipTo')}</label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.existingShipToID}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Ship To Name')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.shipToName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Ship To Gln')}</label>
                                <div className="form-control-textfield">
                                {props.data.othersGlnUpdate.glnShipTo}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                        {props.data.othersGlnUpdate.existingSoldToID != '' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Existing ShipTo')}</label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.existingSoldToID}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Ship To Name')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.soldToName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Ship To Gln')}</label>
                                <div className="form-control-textfield">
                                {props.data.othersGlnUpdate.glnSoldTo}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                        {props.data.othersGlnUpdate.existingPayerID != '' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Existing ShipTo')}</label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.existingPayerID}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Ship To Name')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.othersGlnUpdate.payerName}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Ship To Gln')}</label>
                                <div className="form-control-textfield">
                                {props.data.othersGlnUpdate.glnPayer}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row> */}
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Others - Profit Center Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Others - Profit Center Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersProfitUpdate.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.othersProfitUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Profit Center')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.othersProfitUpdate.profitCenter}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Route To Market' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Route To Market')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.routeToMarket.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.routeToMarket.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.routeToMarket.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.routeToMarket.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.routeToMarket.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attribute Type 5')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.routeToMarket.attributeType5}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivering Plant')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.routeToMarket.deliveringPlant}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('POC Supplier')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.routeToMarket.pocsupplier}
                            </div>
                          </div>
                        </Col>
                        {props.data.mainPage.country == 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Search Term 2')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.routeToMarket.searchTerm2}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'M1 mapping' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('M1 mapping')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.M1mapping.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.M1mapping.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.M1mapping.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.M1mapping.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.M1mapping.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('M1')}</label>
                            <div className="form-control-textfield">{props.data.M1mapping.m1}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('M2')}</label>
                            <div className="form-control-textfield">{props.data.M1mapping.m2}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Ship To Type')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.M1mapping.shipToType}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Use of Tank Beer')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.M1mapping.att7}
                            </div>
                          </div>
                        </Col>
                        {props.data.mainPage.country == 'Italy' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Truck Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.M1mapping.truckType}
                              </div>
                            </div>
                          </Col>
                        )}
                        {props.data.mainPage.country == 'Italy' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Trailer Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.M1mapping.trailerType}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Hierarchy Map Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Hierarchy Map Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Sold-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.hierarchyMapUpdate.existingSoldToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.hierarchyMapUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.hierarchyMapUpdate.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.hierarchyMapUpdate.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.hierarchyMapUpdate.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Hierarchy Type')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.hierarchyMapUpdate.hierarchyType}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Pricing Hierarchy')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.hierarchyMapUpdate.pricingHierarchy}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {props.data.mainPage.reqType == 'Closed Block/Unblock Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Closed Block/Unblock Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.closedBlockUnbUpdate.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.closedBlockUnbUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.closedBlockUnbUpdate.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.closedBlockUnbUpdate.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.closedBlockUnbUpdate.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Order Block')}</label>
                            <div className="form-control-textfield">
                              {props.data.closedBlockUnbUpdate.orderBlock}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivery Block')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.closedBlockUnbUpdate.deliveryBlock}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Billing Block')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.closedBlockUnbUpdate.billingBlock}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Excise Duty Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Excise Duty Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('ED Type')}</label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.edType}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Valid From')}</label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.edValidFrom}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Valid To')}</label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.edValidTo}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Tax Warehouse Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.taxWarehouseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('External ED Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.externalEdNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Partner Types')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.exciseDutyUpdate.partnerType}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Price List' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Price List Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.priceList.existingPayerID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Sales Org')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainPage.salesOrg}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.priceList.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.priceList.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.priceList.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.priceList.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Price List')}</label>
                            <div className="form-control-textfield">
                              {props.data.priceList.priceLists}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Credit Limit' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Other Credit Management')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.creditLimit.existingPayerID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.creditLimit.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Risk Category')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.creditLimit.riskCategory}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Credit Limits')}{' '}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.creditLimit.creditLimits}
                            </div>
                          </div>
                        </Col>
                        {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Terms of Payment')}{' '}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.creditLimit.paymentTerms}
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Payment Term Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Payment Term Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.paymentTermChange.existingPayerID}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.paymentTermChange.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Terms of Payment')}{' '}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.paymentTermChange.paymentTerms}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Accounting Clerk' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Accounting Clerk Modification')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.acctgClerk.existingPayerID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.acctgClerk.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.acctgClerk.street}
                            </div>
                          </div>
                        </Col>
                        {/* <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('House Number')}</label>
                            <div className="form-control-textfield">{props.data.acctgClerk.houseNumber}</div>
                          </div>
                        </Col> */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.acctgClerk.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.acctgClerk.city}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Accounting Clerk')}{' '}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.acctgClerk.acctgClerks}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Regulator Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Regulator Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.regulatorUpdate.existingPayerID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.regulatorUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.regulatorUpdate.street}
                            </div>
                          </div>
                        </Col>
                        {/* <Col xs={6} lg={3}>
                         <div className="form-group">
                           <label className="form-label text-left">{getLang('House Number')}</label>
                           <div className="form-control-textfield">{regulatorUpdateForm.houseNumber}</div>
                         </div>
                       </Col> */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.regulatorUpdate.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.regulatorUpdate.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Regulator')}</label>
                            <div className="form-control-textfield">
                              {props.data.regulatorUpdate.regulator}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Truck Trailer Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Truck / Trailer Type Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.truckTrailerUpdate.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.truckTrailerUpdate.name}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainPage.country}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Sales Org')}</label>
                            <div className="form-control-textfield">
                              {props.data.mainPage.salesOrg}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.truckTrailerUpdate.street}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.truckTrailerUpdate.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.truckTrailerUpdate.city}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Truck Type')}</label>
                            <div className="form-control-textfield">
                              {props.data.truckTrailerUpdate.truckType}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Trailer Type')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.truckTrailerUpdate.trailerType}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Payment Methods Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Payment Method Modification')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.paymentMethodsUpdate.existingPayerID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.paymentMethodsUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.paymentMethodsUpdate.street}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.paymentMethodsUpdate.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.paymentMethodsUpdate.city}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Payment Methods')}{' '}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.paymentMethodsUpdate.paymentMethods}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Segment Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Segment / Sub-Segment Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing ShipTo ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.segmentUpdate.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.segmentUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.segmentUpdate.street}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.segmentUpdate.postalCode}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.segmentUpdate.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Segment ')}</label>
                            <div className="form-control-textfield">
                              {props.data.segmentUpdate.segment}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Sub-Segment')}{' '}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.segmentUpdate.subSegment}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {props.data.mainPage.reqType == 'Address Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Address Update')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.postalCode}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.houseNumber}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')}</label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.region}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Time Zone')}</label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.timeZone}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('PO Box')}</label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.poBox}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('PO Box Postal Code')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.addressUpdate.poBoxPostalCode}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {props.data.mainPage.reqType == 'Delivery Plant Update' && (
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col sm={12} lg="auto">
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                            {getLang('Delivery Plant Update')}
                          </h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.deliveryPlan.existingShipToID}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Requestor Email-ID')}
                            </label>
                            <div className="form-control-textfield">{props.data.requestor}</div>
                          </div>
                        </Col>
                        {props.data.status == 'Rejected' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected By')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectedBy}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left" style={{ color: 'red' }}>
                                  {getLang('Rejected Reason')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.rejectFeed}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')}</label>
                            <div className="form-control-textfield">
                              {props.data.deliveryPlan.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">
                              {props.data.deliveryPlan.street}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">
                              {props.data.deliveryPlan.city}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">
                              {props.data.deliveryPlan.postalCode}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('POC Supplier')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.deliveryPlan.pocsupplier}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivering Plant')}
                            </label>
                            <div className="form-control-textfield">
                              {props.data.deliveryPlan.deliveringPlant}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </>
            )}
          {props.data.requestType == 'Block/Unblock' && (
            <Row>
              <Col lg={12}>
                <Row>
                  <Col sm={12} lg={4}>
                    <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Block/Unblock')}</h2>
                  </Col>
                  <Col xs={6} lg={3}>
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Requestor Email-ID')}
                      </label>
                      <div className="form-control-textfield">{props.data.requestor}</div>
                    </div>
                  </Col>
                  {props.data.status == 'Rejected' && (
                    <>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Rejected By')}</label>
                          <div className="form-control-textfield">{props.data.rejectedBy}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Rejected Reason')}
                          </label>
                          <div className="form-control-textfield">{props.data.rejectFeed}</div>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>

                {props.data.blockUnblock.checkShipTo == 'Y' && (
                  <>
                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang(props.data.blockUnblock.blockUnblockOpt)} {getLang('Ship-to')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.checkShipTo}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Existing Ship-to ID')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.existingShipToID}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')}</label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.nameShipTo}
                          </div>
                        </div>
                      </Col>
                      {props.data.blockUnblock.blockUnblockOpt == 'Block' && (
                        <>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Order Block Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.blockUnblock.orderBlockShipTo}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Delivery Block Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.blockUnblock.deliveryBlockShipTo}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Billing Block Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.blockUnblock.billingBlockShipTo}
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}

                {props.data.blockUnblock.checkPayer == 'Y' && (
                  <>
                    <hr />
                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang(props.data.blockUnblock.blockUnblockOpt)} {getLang('Payer')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.checkPayer}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Existing Payer ID')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.existingPayerID}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')}</label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.namePayer}
                          </div>
                        </div>
                      </Col>
                      {props.data.blockUnblock.blockUnblockOpt == 'Block' && (
                        <>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Order Block Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.blockUnblock.orderBlockPayer}
                              </div>
                            </div>
                          </Col>
                          {!['France', 'Belgium'].includes(props.data.mainPage.country) && (
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Delivery Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.deliveryBlockPayer}
                                </div>
                              </div>
                            </Col>
                          )}
                          {!['France', 'Belgium'].includes(props.data.mainPage.country) && (
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Billing Block Type')}
                                </label>
                                <div className="form-control-textfield">
                                  {props.data.blockUnblock.billingBlockPayer}
                                </div>
                              </div>
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </>
                )}

                {props.data.blockUnblock.checkSoldTo == 'Y' && (
                  <>
                    <hr />
                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang(props.data.blockUnblock.blockUnblockOpt)} {getLang('Sold To')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.checkSoldTo}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Existing Sold To ID')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.existingSoldToID}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')}</label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.nameSoldTo}
                          </div>
                        </div>
                      </Col>
                      {props.data.blockUnblock.blockUnblockOpt == 'Block' && (
                        <>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Order Block Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.blockUnblock.orderBlockSoldTo}
                              </div>
                            </div>
                          </Col>
                          {props.data.mainPage.country != 'Netherlands' && (
                            <>
                              {!['France', 'Belgium'].includes(props.data.mainPage.country) && (
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Delivery Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.deliveryBlockSoldTo}
                                    </div>
                                  </div>
                                </Col>
                              )}
                              {!['France', 'Belgium'].includes(props.data.mainPage.country) && (
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Billing Block Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {props.data.blockUnblock.billingBlockSoldTo}
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {props.data.mainPage.country == 'Netherlands' &&
                        props.data.blockUnblock.blockUnblockOpt == 'Unblock' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Please Attach The Supportings For KVK')}
                              </label>
                              <div className="form-control-textfield">
                                {getFileNameRegex.exec(props.data.blockUnblock.supportDocsBank)
                                  ?.length > 0 && (
                                  <a href={props.data.blockUnblock.supportDocsBank}>
                                    {
                                      getFileNameRegex.exec(
                                        props.data.blockUnblock.supportDocsBank,
                                      )[0]
                                    }
                                  </a>
                                )}
                              </div>
                            </div>
                          </Col>
                        )}
                    </Row>
                  </>
                )}

                {props.data.blockUnblock.checkBillTo == 'Y' && (
                  <>
                    <hr />
                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang(props.data.blockUnblock.blockUnblockOpt)} {getLang('Bill To')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.checkBillTo}
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Existing Bill To ID')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.existingBillToID}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Name')}</label>
                          <div className="form-control-textfield">
                            {props.data.blockUnblock.nameBillTo}
                          </div>
                        </div>
                      </Col>
                      {props.data.blockUnblock.blockUnblockOpt == 'Block' && (
                        <>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Order Block Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.blockUnblock.orderBlockBillTo}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Delivery Block Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.blockUnblock.deliveryBlockBillTo}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Billing Block Type')}
                              </label>
                              <div className="form-control-textfield">
                                {props.data.blockUnblock.billingBlockBillTo}
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          )}

          {props.data.requestType == 'Hierarchy' && (
            <>
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col sm={12} lg={4}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{props.data.mainpage.country}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Requestor_EmailID')}
                        </label>
                        <div className="form-control-textfield">
                          {props.data.mainpage.Requestor_EmailID}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col xs={6} lg={2}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Profile Data')}</h2>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Reference ID')}</label>
                        <div className="form-control-textfield">
                          {props.data.profileData.existingID}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={4}>
                      <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')}</label>
                        <div className="form-control-textfield">{props.data.profileData.name}</div>
                      </div>
                    </Col>
                    {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')} 2</label>
                        <div className="form-control-textfield">{props.data.shipto.name2}</div>
                      </div>
                    </Col> */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Postal Code')}</label>
                        <div className="form-control-textfield">
                          {props.data.profileData.postalCode}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Street')}</label>
                        <div className="form-control-textfield">
                          {props.data.profileData.street}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('House Number')}</label>
                        <div className="form-control-textfield">
                          {props.data.profileData.houseNumber}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('City')}</label>
                        <div className="form-control-textfield">{props.data.profileData.city}</div>
                      </div>
                    </Col>
                    {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{props.data.mainpage.country}</div>
                      </div>
                    </Col> */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Region')}</label>
                        <div className="form-control-textfield">
                          {props.data.profileData.regionDesc}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Language')}</label>
                        <div className="form-control-textfield">
                          {props.data.profileData.language}
                        </div>
                      </div>
                    </Col>
                    {/* {props.data.shipto.telephoneOption === 'General/Accounting' && ( */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Telephone')}</label>
                        <div className="form-control-textfield">
                          {props.data.profileData.telephone}
                        </div>
                      </div>
                    </Col>

                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Mobile')}</label>
                        <div className="form-control-textfield">
                          {props.data.profileData.mobile}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('E-mail Address')}</label>
                        <div className="form-control-textfield">{props.data.profileData.email}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Contact Person')}</label>
                        <div className="form-control-textfield">
                          {props.data.profileData.contact_person}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {props.data.requestType == 'Plant' && (
            <>
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col sm={12} lg={4}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{props.data.mainpage.country}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Requestor_EmailID')}
                        </label>
                        <div className="form-control-textfield">
                          {props.data.mainpage.Requestor_EmailID}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col xs={6} lg={2}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Plant Data')}</h2>
                    </Col>
                    {/* <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Reference ID')}
                          </label>
                          <div className="form-control-textfield">
                            {props.data.plant.existingID}
                          </div>
                        </div>
                      </Col> */}
                  </Row>

                  <Row>
                    <Col xs={12} lg={4}>
                      <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')}</label>
                        <div className="form-control-textfield">{props.data.plantData.name}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')} 2</label>
                        <div className="form-control-textfield">{props.data.plantData.name2}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('VAT Registration No')}.
                        </label>
                        <div className="form-control-textfield">
                          {props.data.plantData.vatRegNumber}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Postal Code')}</label>
                        <div className="form-control-textfield">
                          {props.data.plantData.postalCode}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Street')}</label>
                        <div className="form-control-textfield">{props.data.plantData.street}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('House Number')}</label>
                        <div className="form-control-textfield">
                          {props.data.plantData.houseNumber}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('City')}</label>
                        <div className="form-control-textfield">{props.data.plantData.city}</div>
                      </div>
                    </Col>
                    {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{props.data.mainpage.country}</div>
                      </div>
                    </Col> */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Region')}</label>
                        <div className="form-control-textfield">
                          {props.data.plantData.regionDesc}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Language')}</label>
                        <div className="form-control-textfield">
                          {props.data.plantData.language}
                        </div>
                      </div>
                    </Col>
                    {/* {props.data.shipto.telephoneOption === 'General/Accounting' && ( */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Telephone')}</label>
                        <div className="form-control-textfield">
                          {props.data.plantData.telephone}
                        </div>
                      </div>
                    </Col>
                    {/* // )} */}
                    {/* // {props.data.shipto.telephoneOption === 'Logistics' && ( */}
                    {/* // )} */}
                    {/* // {props.data.shipto.telephoneOption === 'Invoicing' && ( */}
                    {/* // )} */}
                    {/* // {props.data.shipto.telephoneOption === 'Ordering' && ( */}
                    {/* // )} */}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Mobile')}</label>
                        <div className="form-control-textfield">{props.data.plantData.mobile}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('E-mail Address')}</label>
                        <div className="form-control-textfield">{props.data.plantData.email}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Contact Person')}</label>
                        <div className="form-control-textfield" inputMode="text">
                          {props.data.plantData.contactPerson}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} lg={4}>
                      <h3 style={{ color: '#e0702f' }}>{getLang('Sales Data')}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Terms of Payment')}
                        </label>
                        <div className="form-control-textfield">
                          {props.data.plantData.paymentTerms}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Trading Parnter')}</label>
                        <div className="form-control-textfield">
                          {props.data.plantData.tradingPartner}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Trading Parnter')}</label>
                        <div className="form-control-textfield">
                          {props.data.plantData.tradingPartner}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Shipping Condition')}
                        </label>
                        <div className="form-control-textfield">
                          {props.data.plantData.Shipping_condition}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Delivery Plant')}</label>
                        <div className="form-control-textfield">
                          {props.data.plantData.deliveryplant}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}

          <br />
        </div>
      </form>
    </>
  );
};

export default ApprovalReview;
