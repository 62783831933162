import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { Row, ButtonGroup, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';
import { Switch } from '@mantine/core';
import { FetchCountries } from '../mainpage/FlowCallAPI';
import { FetchRegions, FetchShippingConditions, FetchPOCSuppliers } from '../shipto/FlowCallAPI';
import { languages, languageMap, LDEs, LdeMap } from '../payer/Data';
import { useFormFields } from './HooksLib';
import { validateFields } from './FormValidation';
import { FetchPricingHierarchies, FetchConditionGroup2, FetchCustomerGroup3 } from './FlowCallAPI';
import { soldToNamesWhs } from './Config';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';
import CustomTextInput from '../../../components/core/InputFields/CustomTextInput/CustomTextInput';
import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  postalCodeLength,
  postalCodePlaceholder,
  vatLength,
  vatPlaceholder,
} from '../../../utils/Utils';
import {
  FetchBuyingGroups,
  FetchMergers,
  FetchPriceLists,
  FetchSecondSoldToPayers,
} from '../../FlowCallAPI';

const CreateCustSoldTo = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  const [errors, setErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [linkForward, setLinkForward] = useState('');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const [isDisabled, setIsDisabled] = useState(true);

  const [isDisableFields, setIsDisableFields] = useState(false);

  const mainPageData = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));

  // const soldToForm = sessionStorage.getItem('createCustomerSoldTo') === null ? soldToFormTemplate : JSON.parse(sessionStorage.getItem('createCustomerSoldTo'));

  // const [inputs, setInputs] = useFormFields(soldToForm);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType || '';

  const payerID = JSON.parse(sessionStorage.getItem('createCustomerPayer')).existingPayerID || '';

  const customerReqType =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).customerReqType || '';

  const aNodeVal = JSON.parse(sessionStorage.getItem('createCustomerShipTo')).aNode || '';

  const soldToPayer = JSON.parse(sessionStorage.getItem('createCustomerShipTo')).pocPayer || '';

  const pricingHierarchiesRes = FetchPricingHierarchies(country, shipToType);

  const [dataLoaded, setDataLoaded] = useState(false);

  const countriesRes = FetchCountries();

  const regionsRes = FetchRegions(inputs.soldCountry, false);

  const conditionGrp2Res = FetchConditionGroup2(country);

  const shipToData = JSON.parse(sessionStorage.getItem('createCustomerShipTo'));

  const customerGrp3Res = FetchCustomerGroup3(country);

  const mergersRes = FetchMergers(country, shipToType);

  const buyingGroupsRes = FetchBuyingGroups(country, shipToType);

  const belgiumDeliveryOptions = [
    { value: '', label: 'Select' },
    { value: '001', label: 'Del:Mo' },
    { value: '002', label: 'Del:Tu' },
    { value: '003', label: 'Del:We' },
    { value: '004', label: 'Del:Th' },
    { value: '005', label: 'Del:Fr' },
    { value: '012', label: 'Del:Mo,Tu' },
    { value: '013', label: 'Del:Mo,We' },
    { value: '014', label: 'Del:Mo,Th' },
    { value: '015', label: 'Del:Mo,Fr' },
    { value: '023', label: 'Del:Tu,We' },
    { value: '024', label: 'Del:Tu,Th' },
    { value: '025', label: 'Del:Tu,Fr' },
    { value: '034', label: 'Del:We,Th' },
    { value: '035', label: 'Del:We,Fr' },
    { value: '045', label: 'Del:Th,Fr' },
    { value: 'A01', label: 'Inv. per day/mon 1ex' },
    { value: 'A02', label: 'Inv. per day/mon 2ex' },
    { value: 'A03', label: 'Inv. per day/mon 3ex' },
    { value: 'A04', label: 'Inv. per day/mon 4ex' },
    { value: 'A06', label: 'Inv. per day/mon 6ex' },
    { value: 'ALL', label: 'Del:Mo,Tu,We,Th,Fr' },
    { value: 'B01', label: 'Prim Invoice layout' },
    { value: 'B02', label: 'DD Invoice layout' },
    { value: 'B03', label: 'Inv. per del+ret 2ex' },
    { value: 'B04', label: 'Inv. per del+ret 4ex' },
    { value: 'BC0', label: 'No Grouping Logic' },
    { value: 'BC1', label: 'Group by PO number' },
    { value: 'BC2', label: 'Group by Ship-to Par' },
    { value: 'BC3', label: 'Group by POL&POD [EU' },
    { value: 'BC3', label: 'Group by POL&POD [EU' },
    { value: 'BC4', label: 'Group by Material' },
    { value: 'FR1', label: 'EcoTax Inclusion' },
    { value: 'FRI', label: 'Friday delivery' },
    { value: 'GEI', label: 'TT Enterprise Inns' },
    { value: 'GGN', label: 'TT Green King' },
    { value: 'GIU', label: 'TT InBev' },
    { value: 'GTC', label: 'TT TCB' },
    { value: 'GTS', label: 'Country Code to GTS' },
    { value: 'GWH', label: 'TT Whitbread' },
    { value: 'LAC', label: 'PREVIOUS CUSTOMER' },
    { value: 'LBD', label: 'M-IMMO RENT' },
    { value: 'LBL', label: 'PRIVATE RENT' },
    { value: 'LBP', label: 'OWNER RENT' },
    { value: 'LBS', label: 'SUB-LET' },
    { value: 'LCF', label: 'FURNITURE CONTRACT' },
    { value: 'LCL', label: 'FREE CUSTOMER' },
    { value: 'LCN', label: 'NT OWNER CONTRACT (N' },
    { value: 'LCO', label: 'LICENCE' },
    { value: 'LLO', label: 'INDIRECT LODGER' },
    { value: 'MON', label: 'Monday delivery' },
    { value: 'N01', label: 'Del:Tu,We,Th,Fr' },
    { value: 'N02', label: 'Del:Mo,We,Th,Fr' },
    { value: 'N03', label: 'Del:Mo,Tu,Th,Fr' },
    { value: 'N04', label: 'Del:Mo,Tu,We,Fr' },
    { value: 'N05', label: 'Del:Mo,Tu,We,Th' },
    { value: 'N12', label: 'Del:We,Th,Fr' },
    { value: 'N13', label: 'Del:Tu,Th,Fr' },
    { value: 'N14', label: 'Del:Tu,We,Fr' },
    { value: 'N15', label: 'Del:Tu,We,Th' },
    { value: 'N23', label: 'Del:Mo,Th,Fr' },
    { value: 'N24', label: 'Del:Mo,We,Fr' },
    { value: 'N25', label: 'Del:Mo,We,Th' },
    { value: 'N34', label: 'Del:Mo,Tu,Fr' },
    { value: 'N35', label: 'Del:Mo,Tu,Th' },
    { value: 'N45', label: 'Del:Mo,Tu,We' },
    { value: 'R10', label: 'VIP Clients >85 days' },
    { value: 'R20', label: 'Std Clients >60 days' },
    { value: 'R30', label: 'InBev Plants >45 day' },
    { value: 'SAT', label: 'Saturday delivery' },
    { value: 'SUN', label: 'Sunday delivery' },
    { value: 'THU', label: 'Thursday delivery' },
    { value: 'TUE', label: 'Tuesday delivery' },
    { value: 'WED', label: 'Wednesday delivery' },
  ];

  const handlePromoChange = (event) => {
    const { name, checked } = event.target;
    // Do something with the name and checked values
    console.log(`Switch ${name} is ${checked ? 'checked' : 'unchecked'}`);
    setInputs(name, checked);
  };

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      if (!isDisable) {
        setLinkForward(e.target.getAttribute('data-link'));
        setErrors(validateFields(inputs, country, shipToType));
        setIsSubmitting(true);
      } else {
        props.setActivePage(e.target.getAttribute('data-link'));
      }
    }
  };

  function getLang(id) {
    let obj = props.translations.find((o) => o.textID === id);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  const condGrp2Options = conditionGrp2Res.condGrp2.map((val) => {
    return {
      ...val,
      conditionGroup2: val.conditionGroup2,
      conditionGroup2Text: val.conditionGroup2Desc,
      conditionGroup2Desc: `${val.conditionGroup2} - ${val.conditionGroup2Desc}`,
    };
  });

  const custGrp3Options = customerGrp3Res.custGrp3.map((val) => {
    return {
      ...val,
      customerGroup3: val.customerGroup3,
      conditionGroup3Text: val.customerGroup3Desc,
      customerGroup3Desc: `${val.customerGroup3} - ${val.customerGroup3Desc}`,
    };
  });

  const heirarchyNumberOptions = pricingHierarchiesRes.pricingHierarchies.map((val) => {
    return {
      ...val,
      pricingHierarchy: val.pricingHierarchy,
      pricingHierarchyText: val.hierarchyDesc,
      pricingHierarchyDesc: `${val.pricingHierarchy} - ${val.hierarchyDesc}`,
    };
  });
  const name1Length = () => {
    if (inputs.name == 'K&PD_ALL_POCM (Name Merger)') {
      return Number(34 - 16 - inputs.merger.length);
    } else if (inputs.name == 'K&PD_ALL_POCM_3PP (Name Merger)') {
      return Number(34 - 20 - inputs.merger.length);
    } else if (inputs.name == 'BC_ALL (Name Buying Group)') {
      return Number(34 - 9 - inputs.buyingGroup.length);
    } else return Number(34 - inputs.name.length);
  };

  const handleDeliveryChange = (selectedOption, actionMeta) => {
    const event = {
      target: {
        name: actionMeta.name, // The name of the select input
        value: selectedOption ? selectedOption.value : '', // The selected option's value
      },
    };
    setInputs(event, actionMeta);
  };

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      //if ((props.draftTicketNum[0] === 'D')) {
      setIsDisable(true);
    }

    if (inputs.hierarchyTypeANode == 'New') inputs.aNode = 'New';

    if (inputs.soldCountry == '') inputs.soldCountry = country;

    if (country === 'France') {
      inputs.incoTerms1 = shipToType.startsWith('Off-Trade') ? 'FCA' : 'EXW';
      inputs.ediOption = 'No';
      inputs.incoTerms2 = 'HOUPLINES';
    } else if (country === 'Netherlands') {
      if (inputs.hierarchyTypeANode == '') inputs.hierarchyTypeANode = 'Existing';
      if (shipToType.startsWith('Off')) {
        inputs.conditionGroup2 = 'NB';
        inputs.conditionGroup2Desc = 'NB - VORT + SORT';
        inputs.customerGroup3 = '3';
        inputs.customerGroup3Desc = '03 - Spl:Returns';
      }
      if (inputs.att7 == '') inputs.att7 = 'VERKEERDE FUSTEN';

      if (inputs.soldToPayer2 == '') inputs.soldToPayer2 = soldToPayer;

      if (shipToType.startsWith('On-Trade')) {
        inputs.aNode = '102101';
        if (inputs.priceList == '') inputs.priceList = 'N3';
      } else if (shipToType.startsWith('National')) {
        if (inputs.priceList == '') inputs.priceList = 'N3';
      } else if (shipToType.startsWith('Off-Trade')) {
        if (inputs.priceList == '') inputs.priceList = 'N5';
      } else {
        if (inputs.priceList == '')
          inputs.priceList =
            JSON.parse(sessionStorage.getItem('createCustomerShipTo')).pocPriceList || '';
      }

      // if (shipToType.endsWith('Indirect') || shipToType.endsWith('Direct')) {
      //   if (inputs.incoTerms1 == '') inputs.incoTerms1 = 'EXW';
      // }

      if (shipToType.endsWith('Indirect')) {
        if (inputs.promoNews == '') inputs.promoNews = false;
      }

      if (country == 'Belgium') {
        inputs.promoNews = shipToData.promoNews;
      }

      if (shipToType == 'On-Trade – Direct' || shipToType == 'National On-Trade Indirect') {
        inputs.verwerkingstoeslag = 'Yes';
      }
      if (shipToType.startsWith('Off-Trade')) {
        inputs.verwerkingstoeslag = 'No';
      }
    } else if (country == 'United Kingdom') {
      inputs.hierarchyTypeANode = 'Existing';
      if (shipToType.startsWith('On-Trade')) {
        if (inputs.priceList == '') inputs.priceList = 'G2';
      } else if (shipToType.startsWith('Off-Trade')) {
        if (inputs.priceList == '') inputs.priceList = 'G3';
      }
    } else if (country == 'Belgium') {
      inputs.incoTerms1 = 'FCA';
      if (inputs.att7 == '') inputs.att7 = 'No';
      if (shipToType == 'Off-Trade – Direct') {
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        if (customerReqType != 'Prospect') {
          if (inputs.pricingHierarchyOpt == '') inputs.pricingHierarchyOpt = 'Yes';
        }
      }
      if (inputs.pricingHierarchyOpt == '') inputs.pricingHierarchyOpt = 'No';
      if (inputs.pricingHierarchy != '') inputs.pricingHierarchyOpt = 'Yes';
      if (shipToType == 'On-Trade – Direct') {
        if (inputs.promoNews == '') inputs.promoNews = true;
        if (shipToType.startsWith('Wholesaler')) {
          inputs.priceList = 'B5';
          if (inputs.language == '') inputs.language = 'Dutch';
        }
      }
    } else if (country == 'Germany') {
      inputs.ediOption = 'No';
      if (inputs.ldeType === '') {
        inputs.ldeOption = 'No';
      }
      if (inputs.incoTerms1 == '') inputs.incoTerms1 = 'EXW';
      if (inputs.incoTerms2 == '') inputs.incoTerms2 = 'Brewary';
    } else if (['Denmark', 'Finland'].includes(country)) {
      if (inputs.shippingCondition == '') inputs.shippingCondition = 'X1';
    } else if (['Austria', 'Poland', 'Sweden', 'Switzerland', 'Norway'].includes(country)) {
      if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
    } else if (country == 'Italy') {
      inputs.incoTerms1 = 'EXW';
      inputs.incoTerms2 = 'FRANCO';
      if (inputs.pricingHierarchyOpt == '') inputs.pricingHierarchyOpt = 'Yes';
      if (payerID?.length == 8) {
        inputs.pricingHierarchy == '';
      }
    }

    if (aNodeVal != '') inputs.aNode = aNodeVal;

    setDataLoaded(true);
  }, []);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    }
  }, [errors]);

  useEffect(() => {
    sessionStorage.setItem('createCustomerSoldTo', JSON.stringify(inputs));
    if (inputs.hierarchyTypeANode === 'Existing') setIsDisabled(false);
    else setIsDisabled(true);
  }, [inputs]);

  const pricingHierarchyOptions = pricingHierarchiesRes.pricingHierarchies.map((val) => {
    return {
      ...val,
      pricingHierarchy: val.pricingHierarchy,
      hierarchyDesc: val.hierarchyDesc,
      pricingHierarchyDesc: `${val.pricingHierarchy} - ${val.hierarchyDesc}`,
    };
  });

  const shippingConditionsRes = FetchShippingConditions(country, isDisable);

  const priceListsRes = FetchPriceLists(country, isDisable);

  const pocSuppliersRes = FetchPOCSuppliers(country, shipToType, isDisable);

  const secondSoldToPayersRes = FetchSecondSoldToPayers(country, isDisable);

  const secondSoldToPayerOptions = secondSoldToPayersRes.result
    // .filter((e) => e.pocPayer != '')
    .map((val) => {
      return {
        ...val,
        payer: val.payer,
        payerName: val.payerName,
        payerDesc: `${val.payer} - ${val.payerName}`,
      };
    });

  const pocOptions = pocSuppliersRes.pocSuppliers
    .filter((e) => e.pocPayer != '')
    .map((val) => {
      return {
        ...val,
        pocNumber: val.pocNumber,
        pocName: val.pocName,
        pocPayer: val.pocPayer,
        pocPriceListERP: val.pocPriceListERP,
        pocPayerName: val.pocPayerName,
        pocDesc: `${val.pocPayer} - ${val.pocPayerName}`,
      };
    });

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      onClick: handleSubmit,
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      active: false,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      ['data-link']: 'shipto',
      ['data-back']: true,
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      ['data-back']: 'true',
      // type: 'submit',
      active: true,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      // ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
  ];

  return (
    isAuthenticated &&
    dataLoaded && (
      <form className="card" onSubmit={handleSubmit}>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              {/* <strong>Customer Data {getLang('Creation')}</strong> - {getLang('Sold To')} */}
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                {getLang('General Customer Creation')}
              </strong>{' '}
              - {getLang('Sold To')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link
                  data-link="mainpage"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Main Page')}
                </Link>
                <Link
                  data-link="shipto"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {'Ship To'}
                </Link>
                <Link
                  data-link="payer"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Payer')}
                </Link>
                <Button className="btn-danger">{getLang('Sold To')}</Button>
                <Link
                  data-link="billto"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Bill To')}
                </Link>
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body">
              <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('General Data')}</h2>
                    </div>
                  </div>
                  <div className="row">
                    <CustomTextInput
                      title="VAT Registration No"
                      name="vatRegNumber"
                      mandatory={
                        inputs.ediOption === 'Yes' ||
                        (country === 'France' && shipToType === 'Off-Trade – Direct')
                      }
                      inputs={inputs}
                      errors={errors}
                      onChange={setInputs}
                      setInputs={setInputs}
                      maxLength={vatLength(country)}
                      placeholder={vatPlaceholder(country)}
                      disabled={isDisableFields || isDisable}
                      getLang={getLang}
                    />

                    {country == 'United Kingdom' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Attribute Type 9')}
                          </label>
                          <select
                            id="select-attirbute-type-9"
                            className="form-control"
                            name="attributeType9"
                            value={inputs.attributeType9}
                            onChange={setInputs}
                            disabled={
                              isDisable ||
                              (country == 'United Kingdom' && shipToType.startsWith('Off-Trade'))
                            }
                          >
                            <option value="">Select</option>
                            <option value="001">001</option>
                            <option value="002">002</option>
                            <option value="003">003</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {['Netherlands', 'United Kingdom'].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Hierarchy type - A Node')}
                          </label>
                          <select
                            id="select-hierarchy-type-a-node"
                            className="form-control"
                            name="hierarchyTypeANode"
                            value={inputs.hierarchyTypeANode}
                            onChange={setInputs}
                            disabled={isDisable || ['Netherlands'].includes(country)}
                          >
                            <option value="">Select</option>
                            <option value="New">New</option>
                            <option value="Existing">Existing</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {country == 'United Kingdom' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('A-Node')} *</label>
                          <input
                            type="text"
                            className={`form-control ${errors.aNode ? 'is-invalid' : ''}`}
                            name="aNode"
                            maxLength="6"
                            placeholder="Eg: 105004"
                            value={inputs.aNode}
                            onChange={setInputs}
                            disabled={
                              isDisabled || inputs.hierarchyTypeANode != 'Existing' || isDisable
                            }
                          />
                          {errors.aNode && (
                            <div className="invalid-feedback text-left">{errors.aNode}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {country == 'Netherlands' && (
                      // <div className="col-12 col-lg-4">
                      //   <div className="form-group">
                      //     <label className="form-label text-left">
                      //       {getLang('Hierarchy Number')}
                      //     </label>
                      //     <select
                      //       id="select-hierarchy-number"
                      //       className="form-control"
                      //       name="hierarchyNumber"
                      //       value={inputs.hierarchyNumber}
                      //       onChange={setInputs}
                      //       disabled={shipToType.startsWith('On-Trade') || isDisable}
                      //     >
                      //       <option value="">Select</option>
                      //       {!shipToType.startsWith('On-Trade') &&
                      //         pricingHierarchiesRes.pricingHierarchies.map((val) => (
                      //           <option
                      //             key={val.pricingHierarchy}
                      //             value={val.pricingHierarchy}
                      //             data-hdesc={val.hierarchyDesc}
                      //           >
                      //             {`${val.pricingHierarchy} - ${val.hierarchyDesc}`}
                      //           </option>
                      //         ))}
                      //     </select>
                      //   </div>
                      // </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Hierarchy Number')}
                          </label>
                          <Select
                            className="text-left"
                            name="hierarchyNumber"
                            value={heirarchyNumberOptions.filter(
                              (opt) => opt.pricingHierarchy == inputs.hierarchyNumber,
                            )}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            disabled={shipToType.startsWith('On-Trade')}
                            isDisabled={
                              isDisableFields || isDisable || shipToType.startsWith('On-Trade')
                            }
                            getOptionLabel={({ pricingHierarchyDesc }) =>
                              getLang(pricingHierarchyDesc)
                            }
                            getOptionValue={({ pricingHierarchy }) => pricingHierarchy}
                            options={heirarchyNumberOptions}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                          />
                          {errors.hierarchyNumber && (
                            <div className="invalid-feedback-file text-left">
                              {getLang(errors.hierarchyNumber)}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {/* {country == 'Netherlands' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Drop Size')}</label>
                          <select
                            id="select-drop-size"
                            name="dropSize"
                            className="form-control"
                            onChange={setInputs}
                            value={inputs.dropSize}
                            disabled={isDisable}
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    )} */}
                    {country == 'Netherlands' && (
                      <div className="col-12 col-lg-4">
                        <label className="form-label text-left">
                          {getLang('Does the customer need Promo?')}
                        </label>
                        <Switch
                          checked={inputs.promoNews}
                          name="promoNews"
                          onChange={handlePromoChange}
                          onLabel="Yes"
                          offLabel="No"
                          // label="Promo Flag"
                          size="lg"
                          labelPosition="left"
                          color="yellow"
                          disabled={shipToType.endsWith('Indirect') || isDisable}
                        />
                      </div>
                    )}
                    {['Italy', 'Netherlands'].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Delivery Day')}
                            {shipToType.includes('Direct') ? ' *' : ''}
                          </label>
                          <select
                            id="select-delivery-day"
                            name="deliveryDay"
                            className={`form-control${errors.deliveryDay ? ' is-invalid' : ''}`}
                            onChange={setInputs}
                            value={inputs.deliveryDay}
                            disabled={shipToType.endsWith('Indirect') || isDisable}
                          >
                            <option value="">Select</option>
                            <option value="001 - Del:Mo">001 - Del:Mo</option>
                            <option value="002 - Del:Tu">002 - Del:Tu</option>
                            <option value="003 - Del:We">003 - Del:We</option>
                            <option value="004 - Del:Th">004 - Del:Th</option>
                            <option value="005 - Del:Fr">005 - Del:Fr</option>
                            <option value="012 - Del:Mo,Tu">012 - Del:Mo,Tu</option>
                            <option value="013 - Del:Mo,We">013 - Del:Mo,We</option>
                            <option value="014 - Del:Mo,Th">014 - Del:Mo,Th</option>
                            <option value="015 - Del:Mo,Fr">015 - Del:Mo,Fr</option>
                            <option value="023 - Del:Tu,We">023 - Del:Tu,We</option>
                            <option value="024 - Del:Tu,Th">024 - Del:Tu,Th</option>
                            <option value="025 - Del:Tu,Fr">025 - Del:Tu,Fr</option>
                            <option value="034 - Del:We,Th">034 - Del:We,Th</option>
                            <option value="035 - Del:We,Fr">035 - Del:We,Fr</option>
                            <option value="045 - Del:Th,Fr">045 - Del:Th,Fr</option>
                            <option value="ALL - Del:Mo,Tu,We,Th,Fr">
                              ALL - Del:Mo,Tu,We,Th,Fr
                            </option>
                            <option value="N01 - Del:Tu,We,Th,Fr">N01 - Del:Tu,We,Th,Fr</option>
                            <option value="N02 - Del:Mo,We,Th,Fr">N02 - Del:Mo,We,Th,Fr</option>
                            <option value="N03 - Del:Mo,Tu,Th,Fr">N03 - Del:Mo,Tu,Th,Fr</option>
                            <option value="N04 - Del:Mo,Tu,We,Fr">N04 - Del:Mo,Tu,We,Fr</option>
                            <option value="N05 - Del:Mo,Tu,We,Th">N05 - Del:Mo,Tu,We,Th</option>
                            <option value="N12 - Del:We,Th,Fr">N12 - Del:We,Th,Fr</option>
                            <option value="N13 - Del:Tu,Th,Fr">N13 - Del:Tu,Th,Fr</option>
                            <option value="N14 - Del:Tu,We,Fr">N14 - Del:Tu,We,Fr</option>
                            <option value="N15 - Del:Tu,We,Th">N15 - Del:Tu,We,Th</option>
                            <option value="N23 - Del:Mo,Th,Fr">N23 - Del:Mo,Th,Fr</option>
                            <option value="N24 - Del:Mo,We,Fr">N24 - Del:Mo,We,Fr</option>
                            <option value="N25 - Del:Mo,We,Th">N25 - Del:Mo,We,Th</option>
                            <option value="N34 - Del:Mo,Tu,Fr">N34 - Del:Mo,Tu,Fr</option>
                            <option value="N35 - Del:Mo,Tu,Th">N35 - Del:Mo,Tu,Th</option>
                            <option value="N45 - Del:Mo,Tu,We">N45 - Del:Mo,Tu,We</option>
                          </select>
                          {errors.deliveryDay && (
                            <div className="invalid-feedback text-left">{errors.deliveryDay}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {['Belgium'].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Delivery Day')}
                            {shipToType.includes('Direct') ? ' *' : ''}
                          </label>
                          {
                            <Select
                              className="text-left"
                              name="deliveryDay"
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cfcfcf',
                                  primary50: '#cfcfcf',
                                  primary: 'orange',
                                },
                              })}
                              isClearable
                              placeholder="Select"
                              // onChange={(selectedOption) =>
                              //   setInputs((prevInputs) => ({
                              //     ...prevInputs,
                              //     deliveryDay: selectedOption,
                              //   }))
                              // }
                              // onChange={(e, action) => {
                              //   setInputs(e, action);
                              // }}
                              onChange={(selectedOption, actionMeta) =>
                                handleDeliveryChange(selectedOption, { name: 'deliveryDay' })
                              }
                              value={belgiumDeliveryOptions.find(
                                (option) => option.value === inputs.deliveryDay,
                              )}
                              disabled={shipToType.endsWith('Indirect') || isDisable}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              options={belgiumDeliveryOptions}
                              isSearchable
                            />
                          }
                          {errors.deliveryDay && (
                            <div className="invalid-feedback text-left">{errors.deliveryDay}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {country == 'Netherlands' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">VERWERKINGSTOESLAG</label>
                          <select
                            id="select-verwerkingstoeslag"
                            name="verwerkingstoeslag"
                            className="form-control"
                            onChange={setInputs}
                            value={inputs.verwerkingstoeslag}
                            disabled={
                              shipToType.endsWith('Direct') ||
                              shipToType.endsWith('Indirect') ||
                              isDisable
                            }
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {(country == 'Netherlands' ||
                      (country == 'Germany' && shipToType.endsWith('Indirect'))) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {`${
                              shipToType.includes('Direct')
                                ? getLang('Attribute 7 / Cellar beer')
                                : getLang('Use of 2sold-To')
                            }`}
                            {country == 'Netherlands' ? ' *' : ''}
                          </label>
                          <select
                            name="att7"
                            className={`form-control${errors.att7 ? ' is-invalid' : ''}`}
                            onChange={setInputs}
                            value={inputs.att7}
                            disabled={isDisable}
                          >
                            <option value="">Select</option>
                            {country == 'Netherlands' && shipToType.includes('Direct') && (
                              <>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </>
                            )}
                            {country == 'Netherlands' && !shipToType.includes('Direct') && (
                              <>
                                <option value="KELDERBIER">KELDERBIER</option>
                                <option value="VERKEERDE FUSTEN">VERKEERDE FUSTEN</option>
                              </>
                            )}
                            {country == 'Germany' && (
                              <>
                                <option value="BÜ - BÜRGE">{`BÜ - BÜRGE`}</option>
                                <option value="FGH - FGH VERTRAGSPARTNER/ZAHLUNGSEMPFÄNGER">{`FGH - FGH VERTRAGSPARTNER/ZAHLUNGSEMPFÄNGER`}</option>
                                <option value="HE - HAUSEIGENTÜMER VERTRAGSPARTNER/ZAHLUNGSEMPFÄNGER">{`HE - HAUSEIGENTÜMER VERTRAGSPARTNER/ZAHLUNGSEMPFÄNGER`}</option>
                                <option value="OFF - OFF-TRADE VERTRAGSPARTNER/ZAHLUNGSEMPFÄNGER">{`OFF - OFF-TRADE VERTRAGSPARTNER/ZAHLUNGSEMPFÄNGER`}</option>
                                <option value="VKW - VERKAUFSWAGEN VERTRAGSPARTNER">{`VKW - VERKAUFSWAGEN VERTRAGSPARTNER`}</option>
                                <option value="ZE - SONSTIGE VERTRAGSPARTNER/ZAHLUNGSEMPFÄNGER">{`ZE - SONSTIGE VERTRAGSPARTNER/ZAHLUNGSEMPFÄNGER`}</option>
                              </>
                            )}
                          </select>
                          {errors.att7 && (
                            <div className="invalid-feedback text-left">{errors.att7}</div>
                          )}
                        </div>
                      </div>
                    )}

                    {((country == 'Netherlands' &&
                      !['New Owner'].includes(customerReqType) &&
                      shipToType.includes('Indirect')) ||
                      (country == 'Germany' &&
                        shipToType.endsWith('Indirect') &&
                        customerReqType != '2nd Sold To')) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('2 sold-To Payer Number')}
                            {inputs.att7 != '' && customerReqType != '2nd Sold To' ? ' *' : ''}
                          </label>
                          <input
                            type="text"
                            className={`form-control${errors.soldToPayer2 ? ' is-invalid' : ''}`}
                            name="soldToPayer2"
                            maxLength="8"
                            placeholder="Eg: 31234567"
                            value={inputs.soldToPayer2}
                            onChange={setInputs}
                            disabled={isDisable}
                          />
                          {errors.soldToPayer2 && (
                            <div className="invalid-feedback text-left">{errors.soldToPayer2}</div>
                          )}
                        </div>
                      </div>
                    )}

                    {country == 'Netherlands' &&
                      ['New Owner'].includes(customerReqType) &&
                      shipToType.includes('Indirect') && (
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('2 sold-To Payer Number')}
                              {inputs.att7 != '' && customerReqType != '2nd Sold To' ? ' *' : ''}
                            </label>
                            {/* <input
                              type="text"
                              className={`form-control${errors.soldToPayer2 ? ' is-invalid' : ''}`}
                              name="soldToPayer2"
                              maxLength="8"
                              placeholder="Eg: 31234567"
                              value={inputs.soldToPayer2}
                              onChange={setInputs}
                              disabled={isDisable}
                            /> */}
                            {/* <select
                              className={`form-control${errors.soldToPayer2 ? ' is-invalid' : ''}`}
                              name="soldToPayer2"
                              value={inputs.soldToPayer2}
                              onChange={setInputs}
                            >
                              <option value="">Select</option>
                              {pocSuppliersRes.pocSuppliers.map((val) => {})}
                            </select> */}
                            <Select
                              className="text-left"
                              name="soldToPayer2"
                              value={secondSoldToPayerOptions.filter(
                                (opt) => opt.payer == inputs.soldToPayer2,
                              )}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cfcfcf',
                                  primary50: '#cfcfcf',
                                  primary: 'orange',
                                },
                              })}
                              isClearable
                              placeholder="Eg: 30102345"
                              isDisabled={isDisable}
                              getOptionLabel={({ payerDesc }) => payerDesc}
                              getOptionValue={({ payer }) => payer}
                              options={secondSoldToPayerOptions}
                              onChange={(e, action) => {
                                setInputs(e, action);
                              }}
                            />
                            {errors.soldToPayer2 && (
                              <div className="invalid-feedback text-left">
                                {errors.soldToPayer2}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    {(['France', 'Italy'].includes(country) ||
                      (country == 'Germany' && customerReqType != '2nd Sold To') ||
                      (country == 'Belgium' && !shipToType.startsWith('Wholesaler'))) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('INCO Terms 1')}</label>
                          <select
                            id="select-inco1"
                            name="incoTerms1"
                            className="form-control"
                            onChange={setInputs}
                            value={inputs.incoTerms1}
                            disabled
                          >
                            <option value="">Select</option>
                            <option value="EXW">EXW</option>
                            <option value="FCA">FCA</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {(['France', 'Italy'].includes(country) ||
                      (country == 'Germany' && customerReqType != '2nd Sold To')) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('INCO Terms 2')}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="incoTerms2"
                            maxLength="35"
                            value={inputs.incoTerms2}
                            onChange={setInputs}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                    {country == 'Belgium' && shipToType == 'On-Trade – Direct' && (
                      <div className="col-12 col-lg-4">
                        <label className="form-label text-left">
                          {getLang('Does the customer need Promo?')}
                        </label>
                        <Switch
                          checked={inputs.promoNews}
                          name="promoNews"
                          onChange={handlePromoChange}
                          onLabel="Yes"
                          offLabel="No"
                          // label="Promo Flag"
                          size="lg"
                          labelPosition="left"
                          color="yellow"
                          disabled={shipToType.endsWith('Indirect') || isDisable}
                        />
                      </div>
                    )}
                    {((country == 'Belgium' &&
                      (shipToType.startsWith('National') ||
                        ['Off-Trade – Direct', 'On-Trade – Direct', 'On-Trade – Indirect'].includes(
                          shipToType,
                        ))) ||
                      country == 'Italy' ||
                      (country == 'France' &&
                        [
                          'Off-Trade – Direct',
                          'Off-Trade - Indirect',
                          'On-Trade – Direct',
                          'On-Trade – Indirect',
                        ].includes(shipToType))) && (
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Pricing Hierarchy')}
                              {'?'}
                              {shipToType.startsWith('National') ||
                              [
                                'Off-Trade – Direct',
                                'On-Trade – Indirect',
                                'On-Trade – Direct',
                              ].includes(shipToType)
                                ? ' *'
                                : ''}
                            </label>
                            <select
                              name="pricingHierarchyOpt"
                              className={`form-control${
                                errors.pricingHierarchyOpt ? ' is-invalid' : ''
                              }`}
                              onChange={setInputs}
                              value={inputs.pricingHierarchyOpt}
                              disabled={
                                (country == 'Belgium' &&
                                  shipToType == 'Off-Trade – Direct' &&
                                  customerReqType != 'Prospect') ||
                                isDisable
                              }
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>

                            {errors.pricingHierarchyOpt && (
                              <div className="invalid-feedback text-left">
                                {errors.pricingHierarchyOpt}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Pricing Hierarchy')}
                              {inputs.pricingHierarchyOpt == 'Yes' ? ' *' : ''}
                            </label>
                            {
                              <Select
                                className="text-left"
                                name="pricingHierarchy"
                                value={pricingHierarchyOptions.filter(
                                  (opt) => opt.pricingHierarchy == inputs.pricingHierarchy,
                                )}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#cfcfcf',
                                    primary50: '#cfcfcf',
                                    primary: 'orange',
                                  },
                                })}
                                isClearable
                                placeholder="Select"
                                isDisabled={isDisable || inputs.pricingHierarchyOpt != 'Yes'}
                                getOptionLabel={({ pricingHierarchyDesc }) => pricingHierarchyDesc}
                                getOptionValue={({ pricingHierarchy }) => pricingHierarchy}
                                options={pricingHierarchyOptions}
                                onChange={(e, action) => {
                                  setInputs(e, action);
                                }}
                              />
                            }
                            {errors.pricingHierarchy && (
                              <div className="invalid-feedback-file text-left">
                                {errors.pricingHierarchy}
                              </div>
                            )}
                          </div>
                        </div>
                        {country == 'Italy' && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Pricing Hierarchy')}
                                {inputs.pricingHierarchyOpt == 'Yes' ? ' *' : ''}
                              </label>
                              {
                                <input
                                  type="text"
                                  name="pricingHierarchy"
                                  className={`form-control${
                                    errors.pricingHierarchy ? ' is-invalid' : ''
                                  }`}
                                  value={inputs.pricingHierarchy}
                                  onChange={setInputs}
                                  maxLength="8"
                                  disabled={payerID?.length === 8}
                                />
                              }

                              {errors.pricingHierarchy && (
                                <div className="invalid-feedback text-left">
                                  {errors.pricingHierarchy}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {country == 'Belgium' && shipToType == 'On-Trade – Indirect' && (
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {`${
                                shipToType.includes('Direct')
                                  ? getLang('Attribute 7 / Cellar beer')
                                  : getLang('Use of Tankbeer')
                              }`}{' '}
                              *
                            </label>
                            <select
                              id="select-att7"
                              name="att7"
                              className={`form-control${errors.att7 ? ' is-invalid' : ''}`}
                              onChange={setInputs}
                              value={inputs.att7}
                              disabled={isDisable}
                            >
                              {/* <option value="">Select</option> */}
                              <option value="No">No</option>
                              <option value="Cellar Beer">Cellar Beer</option>
                            </select>
                            {errors.att7 && (
                              <div className="invalid-feedback text-left">{errors.att7}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Payer Supplier')}
                              {inputs.att7 == 'No' ? '' : ' *'}
                            </label>
                            <input
                              type="text"
                              className={`form-control${errors.soldToPayer2 ? ' is-invalid' : ''}`}
                              name="soldToPayer2"
                              maxLength="8"
                              placeholder="Eg: 31234567"
                              value={inputs.soldToPayer2}
                              onChange={setInputs}
                              disabled={isDisable || inputs.att7 == 'No'}
                            />
                            {errors.soldToPayer2 && (
                              <div className="invalid-feedback text-left">
                                {errors.soldToPayer2}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {country == 'Belgium' && shipToType.startsWith('Wholesaler') && (
                      <>
                        {customerReqType == 'New Owner' && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Cellar beer')}
                              </label>
                              <select
                                id="select-att7"
                                name="att7"
                                className={`form-control${errors.att7 ? ' is-invalid' : ''}`}
                                onChange={setInputs}
                                value={inputs.att7}
                                disabled={isDisable}
                              >
                                <option value="No">No</option>
                                <option value="Cellar Beer">Cellar Beer</option>
                              </select>
                              {errors.att7 && (
                                <div className="invalid-feedback text-left">{errors.att7}</div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')} *</label>
                            <select
                              name="name"
                              className={`form-control${errors.name ? ' is-invalid' : ''}`}
                              onChange={setInputs}
                              value={inputs.name}
                              disabled={isDisable}
                            >
                              <option value="">Select</option>
                              {soldToNamesWhs.map((name) => (
                                <option key={name} value={name}>
                                  {name}
                                </option>
                              ))}
                            </select>
                            {errors.name && (
                              <div className="invalid-feedback text-left">{errors.name}</div>
                            )}
                          </div>
                        </div>
                        {['NAME(K_POCM)(MERGER)'].includes(inputs.name) && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Merger')} *</label>
                              <select
                                name="merger"
                                value={inputs.merger}
                                onChange={setInputs}
                                className={`form-control${errors.merger ? ' is-invalid' : ''}`}
                              >
                                <option value="">Select</option>
                                {mergersRes.result.map((val) => (
                                  <option key={val.merger} value={val.merger}>
                                    {val.merger}
                                  </option>
                                ))}
                              </select>
                              {errors.merger && (
                                <div className="invalid-feedback text-left">{errors.merger}</div>
                              )}
                            </div>
                          </div>
                        )}
                        {['NAME(B_C_PD)(BUYING GROUP)'].includes(inputs.name) && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Buying Group')} *
                              </label>
                              <select
                                name="buyingGroup"
                                value={inputs.buyingGroup}
                                onChange={setInputs}
                                className={`form-control${errors.buyingGroup ? ' is-invalid' : ''}`}
                              >
                                <option value="">Select</option>
                                {buyingGroupsRes.result.map((val) => (
                                  <option key={val.buyingGroup} value={val.buyingGroup}>
                                    {val.buyingGroup}
                                  </option>
                                ))}
                              </select>
                              {errors.buyingGroup && (
                                <div className="invalid-feedback text-left">
                                  {errors.buyingGroup}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {`${getLang('Name')} 1`} *
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.name1 ? 'is-invalid' : ''}`}
                              name="name1"
                              maxLength={name1Length()}
                              value={inputs.name1}
                              onChange={setInputs}
                              disabled={isDisable}
                            />
                            {errors.name1 && (
                              <div className="invalid-feedback text-left">{errors.name1}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Postal Code')} *
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                              name="postalCode"
                              maxLength={postalCodeLength(country)}
                              placeholder={postalCodePlaceholder(country)}
                              value={inputs.postalCode}
                              onChange={setInputs}
                              disabled={isDisable}
                            />
                            {errors.postalCode && (
                              <div className="invalid-feedback text-left">{errors.postalCode}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')} {country !== 'United Kingdom' ? ' *' : ''}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.houseNumber ? 'is-invalid' : ''}`}
                              name="houseNumber"
                              value={inputs.houseNumber}
                              onChange={setInputs}
                              maxLength="10"
                              disabled={isDisable}
                            />
                            {errors.houseNumber && (
                              <div className="invalid-feedback text-left">{errors.houseNumber}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')} *</label>
                            <input
                              type="text"
                              className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                              name="street"
                              value={inputs.street}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisable}
                            />
                            {errors.street && (
                              <div className="invalid-feedback text-left">{errors.street}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')} *</label>
                            <input
                              type="text"
                              className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                              name="city"
                              value={inputs.city}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisable}
                            />
                            {errors.city && (
                              <div className="invalid-feedback text-left">{errors.city}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')} *</label>
                            <select
                              className={`form-control ${errors.soldCountry ? 'is-invalid' : ''}`}
                              name="soldCountry"
                              onChange={setInputs}
                              value={inputs.soldCountry}
                              disabled={isDisable}
                            >
                              <option value="">Select</option>
                              {countriesRes.countries
                                .filter((e) =>
                                  [
                                    'France',
                                    'Belgium',
                                    'Netherlands',
                                    'United Kingdom',
                                    'Germany',
                                  ].includes(e.country),
                                )
                                .map((val) => (
                                  <option key={val.countryCode} value={val.country}>
                                    {val.country}
                                  </option>
                                ))}
                            </select>
                            {errors.soldCountry && (
                              <div className="invalid-feedback text-left">{errors.soldCountry}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')} *</label>
                            <Select
                              className="text-left"
                              name="region"
                              value={regionOptions.filter((opt) => opt.regionCode == inputs.region)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cfcfcf',
                                  primary50: '#cfcfcf',
                                  primary: 'orange',
                                },
                              })}
                              isClearable
                              placeholder="Select"
                              isDisabled={isDisable}
                              getOptionLabel={({ regionDesc }) => regionDesc}
                              getOptionValue={({ regionCode }) => regionCode}
                              options={regionOptions}
                              onChange={(e, action) => {
                                setInputs(e, action);
                              }}
                            />
                            {errors.region && (
                              <div className="invalid-feedback-file text-left">{errors.region}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Language')}</label>
                            <select
                              id="select-lang"
                              className="form-control"
                              name="language"
                              value={inputs.language}
                              onChange={setInputs}
                              disabled={isDisable}
                            >
                              <option value="">Select</option>
                              {languages.map((lang) => (
                                <option key={lang} value={lang}>
                                  {lang}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Telephone/Fax numbers')}
                            </label>
                            <input
                              type="tel"
                              className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                              name="telephone"
                              placeholder={telephonePlaceholder(country)}
                              maxLength={telephoneLength(country)}
                              value={inputs.telephone}
                              onChange={setInputs}
                              disabled={isDisable}
                            />
                            {errors.telephone && (
                              <div className="invalid-feedback text-left">{errors.telephone}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Mobile')}
                              {['Netherlands', 'United Kingdom', 'France'].includes(country)
                                ? ' *'
                                : ''}
                            </label>
                            <input
                              type="tel"
                              className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                              placeholder={mobilePlaceholder(country)}
                              maxLength={mobileLength(country)}
                              name="mobile"
                              value={inputs.mobile}
                              onChange={setInputs}
                              disabled={isDisable}
                            />
                            {errors.mobile && (
                              <div className="invalid-feedback text-left">{errors.mobile}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')}
                            </label>
                            <input
                              type="email"
                              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                              name="email"
                              placeholder="Eg.: abc@company.com"
                              value={inputs.email}
                              onChange={setInputs}
                              maxLength="240"
                              disabled={isDisable}
                            />
                            {errors.email && (
                              <div className="invalid-feedback text-left">{errors.email}</div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {([
                      'Austria',
                      'Denmark',
                      'Finland',
                      'Italy',
                      'Norway',
                      'Poland',
                      'Sweden',
                      'Switzerland',
                    ].includes(country) ||
                      (country == 'Belgium' && shipToType == 'Off-Trade – Direct')) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Shipping Conditions')}
                            {country == 'Italy' ? ' *' : ''}
                          </label>
                          <select
                            id=""
                            className={`form-control ${
                              errors.shippingCondition ? 'is-invalid' : ''
                            }`}
                            name="shippingCondition"
                            value={inputs.shippingCondition}
                            onChange={setInputs}
                            disabled={isDisable || ['Denmark', 'Finland'].includes(country)}
                          >
                            <option value="">Select</option>
                            {country == 'Belgium' &&
                              shipToType == 'Off-Trade – Direct' &&
                              shippingConditionsRes.shippingConditions
                                .filter((e) => ['T1', 'T2', 'TJ'].includes(e.shippingConditionCode))
                                .map((val) => (
                                  <option
                                    key={val.shippingConditionCode}
                                    value={val.shippingConditionCode}
                                    data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  >
                                    {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  </option>
                                ))}
                            {['Denmark', 'Finland'].includes(country) &&
                              shippingConditionsRes.shippingConditions.map((val) => (
                                <option
                                  key={val.shippingConditionCode}
                                  value={val.shippingConditionCode}
                                  data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                >
                                  {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                </option>
                              ))}
                            {[
                              'Austria',
                              'Italy',
                              'Poland',
                              'Sweden',
                              'Switzerland',
                              'Norway',
                            ].includes(country) &&
                              shippingConditionsRes.shippingConditions
                                .filter((e) => ['T1', 'T2'].includes(e.shippingConditionCode))
                                .map((val) => (
                                  <option
                                    key={val.shippingConditionCode}
                                    value={val.shippingConditionCode}
                                    data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  >
                                    {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  </option>
                                ))}
                          </select>
                          {errors.shippingCondition && (
                            <div className="invalid-feedback text-left">
                              {errors.shippingCondition}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {[
                      'Austria',
                      'Denmark',
                      'Finland',
                      'Italy',
                      'Luxembourg',
                      'Netherlands',
                      'Norway',
                      'Poland',
                      'Sweden',
                      'Switzerland',
                    ].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Price List')}
                            {[
                              'Austria',
                              'Denmark',
                              'Finland',
                              'Italy',
                              'Norway',
                              'Poland',
                              'Sweden',
                              'Switzerland',
                            ].includes(country)
                              ? ' *'
                              : ''}
                          </label>
                          {[
                            'Austria',
                            'Denmark',
                            'Finland',
                            'Italy',
                            'Luxembourg',
                            'Norway',
                            'Poland',
                            'Sweden',
                            'Switzerland',
                          ].includes(country) && (
                            <select
                              id=""
                              className={`form-control ${errors.priceList ? 'is-invalid' : ''}`}
                              name="priceList"
                              value={inputs.priceList}
                              onChange={setInputs}
                              disabled={isDisable}
                            >
                              <option value="">Select</option>
                              {priceListsRes.result.map((val) => (
                                <option
                                  key={val.code}
                                  value={val.code}
                                >{`${val.code} - ${val.desc}`}</option>
                              ))}
                            </select>
                          )}
                          {country == 'Netherlands' && (
                            <input
                              type="text"
                              className="form-control"
                              name="priceList"
                              value={inputs.priceList}
                              maxLength="35"
                              onChange={setInputs}
                              disabled={shipToType != 'Miscellaneous' || isDisable}
                            />
                          )}
                          {errors.priceList && (
                            <div className="invalid-feedback text-left">{errors.priceList}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {country == 'Netherlands' && shipToType.startsWith('Off') && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Condition Group 2')}
                          </label>
                          <Select
                            className="text-left"
                            name="conditionGroup2"
                            value={condGrp2Options.filter(
                              (opt) => opt.conditionGroup2 == inputs.conditionGroup2,
                            )}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            isDisabled={true}
                            Disabled={true}
                            getOptionLabel={({ conditionGroup2Desc }) => conditionGroup2Desc}
                            getOptionValue={({ conditionGroup2 }) => conditionGroup2}
                            options={condGrp2Options}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                          />
                          {errors.conditionGroup2 && (
                            <div className="invalid-feedback-file text-left">
                              {errors.conditionGroup2}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {((country == 'Netherlands' && shipToType.startsWith('Off')) ||
                      country == 'Belgium' ||
                      country == 'France') && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Customer Group 3')}
                          </label>
                          <Select
                            className="text-left"
                            name="customerGroup3"
                            value={custGrp3Options.filter(
                              (opt) => opt.customerGroup3 == inputs.customerGroup3,
                            )}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            isDisabled={
                              country == 'Netherlands' && shipToType.startsWith('Off')
                                ? true
                                : false
                            }
                            getOptionLabel={({ customerGroup3Desc }) => customerGroup3Desc}
                            getOptionValue={({ customerGroup3 }) => customerGroup3}
                            options={custGrp3Options}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                            Disabled={true}
                          />
                          {errors.customerGroup3 && (
                            <div className="invalid-feedback-file text-left">
                              {errors.customerGroup3}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {([
                      'Austria',
                      'Belgium',
                      'Denmark',
                      'Finland',
                      'France',
                      'Italy',
                      'Luxembourg',
                      'Netherlands',
                      'Norway',
                      'France',
                      'Poland',
                      'Sweden',
                      'Switzerland',
                      'United Kingdom',
                    ].includes(country) ||
                      (country == 'Germany' && customerReqType != '2nd Sold To')) && (
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('EDI')}
                              {['France'].includes(country) && shipToType.includes('Off-Trade')
                                ? ' *'
                                : ''}
                            </label>
                            <select
                              name="ediOption"
                              className={`form-control ${errors.ediOption ? 'is-invalid' : ''}`}
                              onChange={setInputs}
                              value={inputs.ediOption}
                              disabled={isDisable}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {errors.ediOption && (
                              <div className="invalid-feedback text-left">
                                {getLang(errors.ediOption)}
                              </div>
                            )}
                          </div>
                        </div>
                        {inputs.ediOption == 'Yes' && (
                          <>
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('GLN/ILN CODE')}
                                  {inputs.ediOption == 'Yes' ? ' *' : ''}
                                </label>
                                <input
                                  type="email"
                                  className={`form-control${
                                    errors.glnIlnCode ? ' is-invalid' : ''
                                  }`}
                                  name="glnIlnCode"
                                  placeholder="Eg: 1234567890123"
                                  maxLength="13"
                                  value={inputs.glnIlnCode}
                                  onChange={setInputs}
                                  disabled={isDisable}
                                />
                                {errors.glnIlnCode && (
                                  <div className="invalid-feedback text-left">
                                    {errors.glnIlnCode}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('EDI Email Address')}
                                  {inputs.ediOption == 'Yes' ? ' *' : ''}
                                </label>
                                <input
                                  type="email"
                                  className={`form-control${
                                    errors.ediEmailAddress ? ' is-invalid' : ''
                                  }`}
                                  name="ediEmailAddress"
                                  placeholder="Eg: abc@company.com"
                                  maxLength="35"
                                  value={inputs.ediEmailAddress}
                                  onChange={setInputs}
                                  disabled={isDisable}
                                />
                                {errors.ediEmailAddress && (
                                  <div className="invalid-feedback text-left">
                                    {errors.ediEmailAddress}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {country == 'Germany' && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('LDE')}</label>
                              <select
                                name="ldeOption"
                                className="form-control"
                                onChange={setInputs}
                                value={inputs.ldeOption}
                                disabled={isDisable}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                        )}
                        {inputs.ldeOption == 'Yes' && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('LDE Option')}
                                {inputs.ldeOption == 'Yes' ? ' *' : ''}
                              </label>
                              <select
                                name="ldeType"
                                className={`form-control${errors.ldeType ? ' is-invalid' : ''}`}
                                onChange={setInputs}
                                value={inputs.ldeType}
                                disabled={isDisable}
                              >
                                <option value="">Select</option>
                                {Object.entries(LdeMap).map(([key, value]) => (
                                  <option key={value} value={key}>
                                    {value}
                                  </option>
                                ))}
                                {/* <option value="16">'<=16'</option>
                                <option value="24">17-24</option>
                                <option value="30">30>=</option> */}
                              </select>
                              {errors.ldeType && (
                                <div className="invalid-feedback text-left">{errors.ldeType}</div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Row>

        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button variant="outline-warning" onClick={props.saveDraft}>
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left justify-content">{props.draftMsg}</div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="payer"
                data-back="true"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="billto"
                className={`btn btn-${
                  Object.keys(validateFields(inputs)).length == 0 || isDisable
                    ? 'danger'
                    : 'secondary'
                } btn-block`}
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

export default CreateCustSoldTo;
